import {
    notificationToShow,
    notifyIntergerError,
    notifyIntergerTooBigError,
    NOTIFY_DATE_ERROR,
    NOTIFY_FORBIDDEN_ERROR,
    NOTIFY_TEXT_LENGTH_ERROR,
    openNotification,
} from ".";
import { iNotify } from "../types";

const SEQUELIZE_ERROR_TYPE = {
    INTEGER: "invalid input syntax for type integer",
    DATE: "invalid input syntax for type date",
    INTEGER_TOO_BIG: "is out of range for type integer",
    CHARACTERS_TOO_LONG: "value too long for type character",
};

const RESPONSE_STATUS_CODE = {
    FORBIDDEN: 403,
};

export const processResponse = (e: any, notifyObj: iNotify) => {
    // Check for common sequelize errors. If not found than
    // show a normal error. Meaning we are not sure why the error is
    // being thrown
    if (typeof e?.error === "string") {
        const [msg, val] = e.error.split(":");

        if (msg.search(SEQUELIZE_ERROR_TYPE.INTEGER) !== -1) {
            openNotification(notifyIntergerError(val));
            return;
        }

        if (msg.search(SEQUELIZE_ERROR_TYPE.DATE) !== -1) {
            openNotification(NOTIFY_DATE_ERROR);
            return;
        }

        if (msg.search(SEQUELIZE_ERROR_TYPE.CHARACTERS_TOO_LONG) !== -1) {
            openNotification(NOTIFY_TEXT_LENGTH_ERROR);
            return;
        }

        if (msg.search(SEQUELIZE_ERROR_TYPE.INTEGER_TOO_BIG) !== -1) {
            openNotification(notifyIntergerTooBigError(val));
            return;
        }
    }

    // Check for 403(Forbidden) in response status
    if (e?.status === RESPONSE_STATUS_CODE.FORBIDDEN) {
        openNotification(NOTIFY_FORBIDDEN_ERROR);
        return;
    }

    notificationToShow(e, notifyObj);
};
