import { useState } from "react";
export default function useEditing() {
    const [editing, setEditing] = useState(true);

    const handleEdit = () => {
        setEditing(true);
    };
    const handleSave = () => {
        // setEditing(false);
        //Save data
    };
    const handleCancel = () => {
        setEditing(false);
    };
    return {
        editing,
        handleEdit,
        handleSave,
        handleCancel,
    };
}
