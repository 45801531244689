import { ThemeProvider, createTheme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { CancelIcon } from "../../icons";
type InputProps = {
    title?: string;
    checkboxTitle?: string;
    checkboxNotClickable?: boolean;
    type?:
        | "text"
        | "radio"
        | "checkbox"
        | "textarea"
        | "number"
        | "email"
        | "password"
        | "date"
        | "time"
        | "card"
        | "address";
    placeholder?: string;
    name?: string;
    onChangeFunction?: (arg0: string) => void;
    options?: { name: string }[];
    className?: string;
    inputClassName?: string;
    titleClassname?: string;
    editing?: boolean;
    textBoxClass?: string;
    value?: string | number;
    isChecked?: boolean;
    isDisabled?: boolean;
    children?: JSX.Element | false | undefined;
    style?: any;
    uri?: string;
    titleWrap?: boolean;
    single_day_value?: string[];
    handle_single_day?: (value: any) => void;
    hidden?: boolean;
};
function Input({
    title,
    checkboxTitle,
    checkboxNotClickable,
    type = "text",
    placeholder,
    name = "",
    onChangeFunction,
    options,
    className,
    inputClassName,
    titleClassname,
    editing,
    textBoxClass,
    value = "",
    isChecked = false,
    isDisabled,
    children,
    style,
    uri,
    titleWrap = false,
    single_day_value,
    handle_single_day,
    hidden,
}: InputProps) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [check, setCheck] = useState(true);
    const theme = createTheme({
        components: {},
    });
    const datePicker = useRef<HTMLInputElement>(null);
    return (
        <div className={`flex ${className ? className : " flex-col"} ${hidden && "hidden"}`}>
            {title && (
                <div
                    className={`${!titleWrap && "whitespace-nowrap"} text-r-medium-small-2 ${
                        titleClassname ? titleClassname : ""
                    }`}
                >
                    {title}
                </div>
            )}
            {type === "radio" && options && options.length > 0 ? (
                <div className="form-radio-button">
                    {options.map((option) => {
                        return (
                            <div className="flex items-center gap-2" key={name + option.name}>
                                <input
                                    disabled={editing === false}
                                    className={editing === false ? " bg-reyna-grey-3" : " bg-white"}
                                    type="radio"
                                    name={title}
                                    id={name + option.name}
                                    onChange={(e) => {
                                        onChangeFunction && onChangeFunction(e.target.value);
                                    }}
                                    checked={option.name === value}
                                    value={option.name}
                                    autoComplete="off"
                                />
                                <label
                                    className={
                                        "font-inter capitalize text-r-medium-small-2 text-reyna-text-2 " + inputClassName
                                    }
                                    htmlFor={name + option.name}
                                >
                                    {option.name}
                                </label>
                            </div>
                        );
                    })}
                </div>
            ) : type === "checkbox" ? (
                <label className="flex items-center gap-2 font-inter text-reyna-primary text-r-medium-small-2">
                    <input
                        name={name}
                        className={`outline-none text-reyna-primary  ${
                            !editing ? " bg-reyna-grey-3" : " bg-white"
                        } rounded-[7px] border-reyna-grey-1 focus:ring-reyna-primary focus:border-reyna-primary transition-all ${
                            inputClassName ? inputClassName : ""
                        } `}
                        type={type}
                        placeholder={placeholder}
                        onChange={(e) => {
                            onChangeFunction && onChangeFunction(e.target.value);
                        }}
                        disabled={editing === false || checkboxNotClickable === true}
                        value={value && value}
                        checked={isChecked}
                        style={{ ...style }}
                    />
                    {checkboxTitle}
                </label>
            ) : type === "textarea" ? (
                <span
                    contentEditable={editing}
                    role="textbox"
                    className={
                        (textBoxClass
                            ? textBoxClass
                            : "border border-reyna-grey-1 bg-white rounded-[7px] py-1 px-2 focus:ring-reyna-primary focus:border-reyna-primary-dark ") +
                        (editing === false ? " bg-reyna-grey-3 " : "")
                    }
                    onChange={(e) => {
                        onChangeFunction &&
                            onChangeFunction((e.target as HTMLSpanElement).innerText);
                    }}
                >
                    {value && value}
                </span>
            ) : type === "date" ? (
                // <input
                //     name={name}
                //     className={`outline-none text-reyna-primary border-reyna-grey-1 text-r-medium-small-2 focus:ring-reyna-primary
                //       focus:border-reyna-primary transition-all rounded-[7px] py-1 px-2 w-full
                //       ${inputClassName ? inputClassName : " bg-white"} ${
                //         editing === false ? " bg-reyna-grey-3 " : ""
                //     } `}
                //     type={type}
                //     onFocus={(e) => {
                //         setCheck(false);
                //     }}
                //     onBlur={(e) => {
                //         setCheck(true);
                //     }}
                //     placeholder={
                //         type === "date"
                //             ? !placeholder
                //                 ? "Select date..."
                //                 : placeholder
                //             : placeholder
                //     }
                //     onChange={(e) => {
                //         onChangeFunction?.(e.target.value);
                //     }}
                //     disabled={editing === false}
                // value={
                //     type === "date" && value
                //         ? new Date(value).toLocaleDateString("fr-CA")
                //         : value && value
                // }
                // />
                <div className="flex flex-col relative w-full gap-1">
                    <div className="flex relative w-full">
                        <ThemeProvider theme={theme}>
                            {/* Use dayjs instead of JS Date/date-fns to avoid 
                            https://github.com/moment/luxon/issues/401 */}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    openTo="day"
                                    disabled={editing === false || isDisabled}
                                    views={["year", "month", "day"]}
                                    format="MM/DD/YYYY"
                                    value={value ? dayjs(value) : null}
                                    onChange={(newValue: dayjs.Dayjs | null) => {
                                        onChangeFunction?.(
                                            newValue ? newValue.format("YYYY-MM-DD") : ""
                                        );
                                    }}
                                    inputRef={datePicker}
                                    slotProps={{
                                        textField: {
                                            className: `h-[36px]`,
                                            InputProps: {
                                                className: `h-[36px]`,
                                                placeholder: "mm/dd/yyyy",
                                            },
                                        },
                                        field: {
                                            className: `w-full text-reyna-primary text-r-medium-small-2 transition-all px-2 h-[36px] ${
                                                inputClassName ? inputClassName : " bg-white"
                                            } ${editing === false ? " bg-reyna-grey-3 " : ""}`,
                                            ref: datePicker,
                                        },
                                    }}
                                    className={`p-0`}
                                />
                            </LocalizationProvider>
                        </ThemeProvider>
                        <CancelIcon
                            className={`absolute right-10 my-[10px] w-[20px] h-[20px] brightness-0 cursor-pointer opacity-50 hover:opacity-100 ${
                                !editing && "opacity-50"
                            }`}
                            onClick={() => {
                                if (datePicker?.current?.value) {
                                    onChangeFunction?.("");
                                }
                            }}
                        />
                    </div>
                    {single_day_value !== undefined && (
                        <div>
                            <label className="flex items-center gap-2 font-inter text-reyna-primary text-r-medium-small">
                                <input
                                    className={`outline-none text-reyna-primary  ${
                                        !editing ? " bg-reyna-grey-3" : " bg-white"
                                    }  !border-reyna-grey-1 !rounded-md focus:ring-reyna-primary focus:border-reyna-primary transition-all`}
                                    type="checkbox"
                                    onChange={(e) => {
                                        handle_single_day && handle_single_day(e.target.value);
                                    }}
                                    disabled={false}
                                    value={name}
                                    checked={single_day_value?.includes(name)}
                                />
                                Single Day
                            </label>
                        </div>
                    )}
                </div>
            ) : type == "card" ? (
                <div
                    className={`flex flex-row outline-none text-reyna-primary h-[38px] border-reyna-grey-1 overflow-hidden border text-r-medium-small-2 focus:ring-reyna-primary
                        focus:border-reyna-primary transition-all rounded-[7px]
                         ${!editing && " bg-reyna-grey-3"}`}
                >
                    <img src={uri} alt="" className={"py-2 px-2"} />
                    <input
                        name={name}
                        style={style}
                        type={"text"}
                        className={`outline-none w-full border-none ${
                            !editing && " bg-reyna-grey-3"
                        } ${inputClassName ? inputClassName : " bg-white"}`}
                        onFocus={(e) => {
                            setCheck(false);
                        }}
                        onBlur={(e) => {
                            setCheck(true);
                        }}
                        placeholder={placeholder}
                        onChange={(e) => {
                            onChangeFunction?.(e.target.value);
                        }}
                        disabled={editing === false}
                        value={value && value}
                    />
                </div>
            ) : (
                <input
                    name={name}
                    style={style}
                    className={`outline-none text-reyna-primary border-reyna-grey-1 h-[38px] text-r-medium-small-2 focus:ring-reyna-primary
                        focus:border-reyna-primary transition-all rounded-[7px] py-1 px-2
                        ${inputClassName ? inputClassName : " bg-white"} ${
                        editing === false ? " bg-reyna-grey-3 " : ""
                    } `}
                    type={type === "address" ? "text" : type}
                    data-address={type === "address"}
                    onFocus={(e) => {
                        setCheck(false);
                    }}
                    onBlur={(e) => {
                        setCheck(true);
                    }}
                    placeholder={placeholder}
                    onChange={(e) => {
                        onChangeFunction?.(e.target.value);
                    }}
                    disabled={editing === false}
                    value={value && value}
                />
            )}
            {/* You can pass a component to display stuff under the input. Its usually used for
                displaying error msges */}
            {children && children}
        </div>
    );
}

export default Input;
