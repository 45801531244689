import { CustomSelect } from "./genericDropdowns/CustomSelect";

type InputProps = {
    title?: string;
    placeholder?: string;
    name?: string;
    onChangeFunction: (arg0: boolean | null) => void;
    className?: string;
    inputClassName?: string;
    titleClassname?: string;
    editing?: boolean;
    textBoxClass?: string;
    value?: boolean | null;
    trueText: string;
    falseText: string;
    control?: React.CSSProperties;
};

export const DropDownAcceptDecline = ({
    title,
    placeholder,
    name = "",
    onChangeFunction,
    className,
    inputClassName,
    titleClassname,
    editing,
    textBoxClass,
    value = false,
    trueText,
    falseText,
    control,
}: InputProps) => {
    const handleChange = (val: any, action: any) => {
        if (action.action === "clear") {
            onChangeFunction(null);
            return "";
        }
        switch (val.value) {
            case trueText:
                onChangeFunction(true);
                break;
            case falseText:
                onChangeFunction(false);
                break;
            default:
                onChangeFunction(null);
                break;
        }
    };

    const toLabelValue = (value: string) => {
        return { value: value, label: value };
    };

    return (
        <CustomSelect
            title={title}
            placeholder={placeholder}
            name={name}
            control={control}
            onChangeFunction={handleChange}
            className={className ? `flex flex-col ${className}` : "flex flex-col mb-4"}
            titleClassName={titleClassname ? titleClassname : "text-r-medium-small-2"}
            editing={editing}
            value={value === null ? "" : value ? trueText : falseText}
            options={[toLabelValue(trueText), toLabelValue(falseText)]}
            containerClassname="capitalize"
        />
    );
};
