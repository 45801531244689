import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { API_BULK, API_BULK_BASEURL, buildUrl } from "../config";
import { fetcherDelete, NOTIFY_REPORT_DELETED } from "../utils";
import { getToken } from "../utils/auth";
import { processResponse } from "../utils/error";

// 682444644
export const useDeleteReport = (
    onSuccessTrigger?: () => void
): [(val: string) => void, boolean, Function] => {
    const auth0 = useAuth0();

    const [isLoading, setLoading] = useState(false); // Used for loading indicator while saving

    const [id, setId] = useState(""); // Used for loading indicator while saving

    const triggerDelete = () => {
        if (!isLoading) setLoading(true);
    };

    useEffect(() => {
        let mounted = true;

        const deleteReport = async () => {
            const url = buildUrl(API_BULK.DELETE_REPORT, { id }, API_BULK_BASEURL);
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [result, error] = await fetcherDelete(url, await getToken(auth0), "");

            if (mounted) {
                processResponse(error, NOTIFY_REPORT_DELETED);
                if (onSuccessTrigger) onSuccessTrigger();
            }

            setLoading(false); // stop loading indicator for either case.
        };

        if (isLoading && id) deleteReport();

        return () => {
            mounted = false;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, id]);

    return [setId, isLoading, triggerDelete];
};
