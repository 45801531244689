import React from "react";
import { NavLink } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ReactDOM from "react-dom";

export type SidebarLinkProps = {
    name: string;
    url: string;
    tooltip: string;
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    toggleFunction: (arg0: boolean) => void;
    active: boolean;
    notifications?: number;
};

export default function SidebarLink({
    tooltip,
    name,
    url,
    Icon,
    toggleFunction,
    active,
    notifications
}: SidebarLinkProps) {
    const tooltipContainer = document.getElementById("tooltip-container");
    return (
        <NavLink
            to={url}
            className={`2xl:py-4 p-2 overflow-visible flex transition-all items-center sidebar-link min-h-[62px] ${!active ? "opacity-50" : ""
                }`}
            activeClassName="navbar-link-active"
            aria-disabled={active || tooltip ? true : false}
            key={name}
            onClick={(e) => {
                if (!active) {
                    e.preventDefault();
                } else {
                    toggleFunction(false);
                }
            }}
        >
            <div
                data-tip
                data-tooltip-id={active || tooltip ? name : ""}
                className={`relative flex overflow-visible rounded p-[10px] hover:bg-reyna-primary-dark items-center gap-4 ${!active ? "opacity-50" : ""
                    }`}
            >
                {Icon && <Icon className="fill-current transition-all" />}
                <span className="md:hidden text-sm">{name}</span>
                {notifications !== undefined && notifications > 0 && (
                    <div className="absolute top-1 right-0 rounded-full bg-red-600 text-white text-xs w-[18px] h-[18px] flex items-center justify-center">
                        <span>{notifications}</span>
                    </div>
                )}
            </div>
            {tooltipContainer &&
                ReactDOM.createPortal(
                    <ReactTooltip id={name} place="right" className="overflow-visible z-50">
                        <span>{tooltip}</span>
                    </ReactTooltip>,
                    tooltipContainer
                )}
        </NavLink>
    );
}
