export type FeedBoxItemProps = {
    name?: string;
    text1?: string;
    text2?: string;
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

function FeedBoxItem({ name, text1, text2, Icon }: FeedBoxItemProps) {
    return (
        <div className="flex items-center gap-4 relative mb-5">
            <div className="bg-reyna-primary p-2 rounded-full w-9 h-9 flex items-center justify-center feedBoxItem__iconWrapper ring ring-white z-0">
                <Icon className="fill-current text-reyna-secondary" />
            </div>
            <div className="flex flex-col ">
                <div className="font-inter font-medium text-r-small-medium text-reyna-text-2">
                    {name}
                </div>
                <div className="font-inter text- text-reyna-text-3 text-r-very-small">
                    {text1 && text1}
                </div>
                <div className="font-inter text- text-reyna-text-3 text-r-very-small">
                    {text2 && text2}
                </div>
            </div>
        </div>
    );
}

export default FeedBoxItem;
