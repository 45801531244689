import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { fetcherGetDownload, NOTIFY_VIEW_GENERATED } from "../utils";
import { getToken } from "../utils/auth";
import { processResponse } from "../utils/error";

export const useViewDocument = (
    onSuccessTrigger?: () => void
): [(val: string) => void, Function, boolean] => {
    const auth0 = useAuth0();

    const [isLoading, setLoading] = useState(false); // Used for loading indicator while saving
    const [url, setUrl] = useState(""); // Used for loading indicator while saving

    const triggerView = () => {
        if (!isLoading) setLoading(true);
    };

    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            const [result, error] = await fetcherGetDownload(url, await getToken(auth0));

            processResponse(error, NOTIFY_VIEW_GENERATED);

            if (result && mounted) {
                const splitArray = result.url.split(".");
                const ALL_TYPES = [
                    "image/png",
                    "image/jpg",
                    "image/jpeg",
                    "image/svg",
                    "image/gif",
                    "application/pdf",
                    "text/plain",
                    "audio/mpeg",
                    "audio/wav",
                ];
                // var type =
                //     ALL_TYPES[
                //         ALL_TYPES.findIndex((el) => el.includes(splitArray[splitArray.length - 1]))
                //     ];
                var type =
                    ALL_TYPES[
                    ALL_TYPES.findIndex(
                        (el) => el.split("/")[1] == splitArray[splitArray.length - 1].toLowerCase()
                    )
                    ];
                type = (splitArray[splitArray.length - 1] == "mp3" && "audio/mpeg") || type;

                const buffer = await result.arrayBuffer();
                const fileBuffer = new File([buffer], "file");
                const blob = new Blob([fileBuffer], {
                    type: type,
                });
                const viewBlobUrl = (window.URL ? URL : webkitURL).createObjectURL(blob);
                window.open(viewBlobUrl, "_blank");
                if (onSuccessTrigger) onSuccessTrigger();
            }

            setLoading(false); // stop loading indicator for either case.
        };

        if (isLoading && url) fetchData();

        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, url]);

    return [setUrl, triggerView, isLoading];
};
