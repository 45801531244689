export const FORM_MSG = {
    PASSWORD_ERROR:
        "* Password must be 8 characters long with at least one number, one lowercase letter, one uppercase letter, and no special characters.",
    PASSWORD_MUST_MATCH: "* Passwords must match",
    REQUIRED: "* Required",
    INVALID_PHONE: "* Provide a valid phone number.",
    INVALID_EMAIL: "* Provide a valid email address",
    INVALID_NUMBER: "* Provide a valid number",
    INVALID_DATE: "* Provide a valid date with format mm/dd/yyyy",
    INVALID_SCORE: "* Provide a valid number or 'NA'",
    INVALID_CARD: "* Provide provide a valid VISA/MASTER/American Express/Discover card",
    TABLE_FIELD_MISSING: "Some required columns are missing",
    MAX_LENGTH: (val: number) => generateLengthMsg(val),
    LENGTH: (val: number) => generateLengthNotValidMessage(val),
    MAX: (val: number) => generateMaxMsg(val),
    MIN: (val: number) => generateMinMessage(val),
    END_DATE_ERROR: "* End date must be after start date, or the same date",
    END_TIME_ERROR: "* End time must be after start time",
    INVALID_SSN: "* SSN must be XXX-XX-XXXX",
    NUM_MAX: (length: number) => `Number should be maximum of length ${length}`,
    NUM_MIN: (length: number) => `Number should be minimum of length ${length}`,
    PRICE_PROGRAM: "Price cannot be lower than cost."
};

/**
 * A helper function to help generate Max length messeages
 * @param val number
 * @returns {Stirng} a msg with the number in it
 */
const generateLengthMsg = (val: number) => {
    return `* Value is too long (maximum is ${val} characters)`;
};
const generateLengthNotValidMessage = (val: number) => {
    return `* Value must be of ${val} characters`;
};

/**
 * A helper function to help generate Max and minmesseages
 * @param val number
 * @returns {Stirng} a msg with the number in it
 */
const generateMaxMsg = (val: number) => {
    return `* Maximum value is ${val}`;
};
const generateMinMessage = (val: number) => {
    return `* Minimum value is ${val}`;
};
