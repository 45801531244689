const production = process.env.NODE_ENV === "production";
// Url for main backend server
export const API_BASEURL = production
    ? process.env.REACT_APP_API_PUBLIC_URL
    : "http://localhost:3001";

// Url for csv bulk upload feature. It uses a different server.
export const API_BULK_BASEURL = production
    ? process.env.REACT_APP_BULK_PUBLIC_URL
    : "http://localhost:9001";
