import React from "react";
import image from "../../assets/img/something-went-wrong.png";
import { datadogRum } from "@datadog/browser-rum";

interface iState {
    error: any;
    errorInfo: any;
}

export class ErrorBoundary extends React.Component<{}, iState> {
    constructor(props: any) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });

        //Send error to datadog
        datadogRum.addError(error, errorInfo);
    }

    // Refresh the app to homepage
    handleClick = () => (window.location.href = "/");

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <div className="w-full h-screen flex justify-center items-center">
                    <div className="flex justify-center space-y-8 flex-col text-center">
                        <img src={image} className="h-60 w-full" alt="something went wrong" />
                        <h2 className="text-reyna-primary text-3xl">Oops!</h2>
                        <p className="text-reyna-primary text-2xl">Something went wrong.</p>
                        <button className="button-crm px-4 py-2" onClick={this.handleClick}>
                            Refresh application
                        </button>
                        {/* To see more details regarding the error uncomment the code below */}
                        {/* <details style={{ whiteSpace: "pre-wrap" }}>
                            {this.state.error && this.state.error.toString()}
                            <br />
                            {this.state.errorInfo.componentStack}
                        </details> */}
                    </div>
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}
