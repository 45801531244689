export const REGEX_TEST = {
    // phone numbers for spain, usa and mexico
    PHONE: /^(\+\d{1,2}\s)\(?\d{2,3}\)?[\s.-]?\d{3}[\s.-]?\d{4}|(\+34\s)\d{3}\s\d{3}\s\d{3}|(\(\d{3}\)[.-]?|\d{3}[.-]?)?\d{3}[.-]?\d{4}$/, //+34 (23) 567-8901 example number for spain
    PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z!@#$%^&*)(+=._~-\d]{8,}$/,
    EMAIL: /^\S+@\S+$/,
    NUMBER: /^[0-9]*$/,
    DATE: /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/,
    DATE_MMDDYYYY: /^(0\[1-9]|1\[0-2\])\/(0\[1-9]|1\d|2\\d|3\[01])\/(19|20)\d{2}$/,
    SCORE: /(^NA$)|(^na$)|(^\d*$)/,
    DECIMAL: /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/,
    DECIMAL_COMMAS: /^(?:\d{1,3}(?:,\d{3})*|\d+)(?:\.\d+)?$/,
    SSN: /^\d{3}-\d{2}-\d{4}$/,
    CREDIT_CARD:
        /^(?:4[0-9]{12}(?:[0-9]{3})?|(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|3[47][0-9]{13}|6(?:011|5[0-9]{2})[0-9]{12})$/,
};
