/* eslint-disable eqeqeq */
import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

interface ScrollToTopProps {
    history: any,
    children?: any
}

function ScrollToTop({ history, children }: ScrollToTopProps) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      if(window.location.pathname.search("leads") != -1 || window.location.pathname.search("clients") != -1) {
        return;
      }
      else  {
        window.scrollTo(0, 0)
      };
    });
    return () => {
      unlisten();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);