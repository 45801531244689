import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { API_BULK, API_BULK_BASEURL, buildUrl } from "../config";
import { REPORT_LIMIT } from "../helpers/ExportReportPopup";
import { fetcherGet, notificationToShow, NOTIFY_PREVIEW, openNotification } from "../utils";
import { getToken } from "../utils/auth";

// 682444644
export const usePreviewReport = (
    onSuccessTrigger?: () => void
): [any[], boolean, Function, (val: {}) => void] => {
    const auth0 = useAuth0();

    const [isLoading, setLoading] = useState(false); // Used for loading indicator while saving
    const [queries, setQueries] = useState({}); // Used for loading indicator while saving
    const [data, setData] = useState<any[]>([]);

    const triggerGet = () => {
        if (!isLoading) setLoading(true);
    };

    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            const url = buildUrl(API_BULK.PREVIEW, {}, API_BULK_BASEURL, {
                ...queries,
                limit: REPORT_LIMIT,
            });
            const result = await fetcherGet(url, await getToken(auth0));

            if (mounted && !result?.data) {
                notificationToShow(true, NOTIFY_PREVIEW); // Found error
            }

            if (mounted && result?.data?.length) {
                setData(result.data);
                openNotification(NOTIFY_PREVIEW.OK);
                if (onSuccessTrigger) onSuccessTrigger();
            }

            if (mounted && result?.data?.length === 0) {
                setData([]);
                openNotification(NOTIFY_PREVIEW.OK_BUT_NONE);
            }

            setLoading(false); // stop loading indicator for either case.
        };

        if (isLoading) fetchData();

        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, queries]);

    return [data, isLoading, triggerGet, setQueries];
};
