import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { API, API_BASEURL, buildUrl, POPUP_STATE, ROUTES } from "../config";
import { recAddToClientPopup } from "../config/recoil/atoms";
import {
    fetcherPut,
    NOTIFY_LEAD_CONVERTED,
} from "../utils";
import { getToken } from "../utils/auth";
import { processResponse } from "../utils/error";
import { recAlreadyExistPopup } from "../config/recoil/atoms";
export const useConvertLeadToClient = (id: string, successTrigger?: Function, email?: string) => {
    const history = useHistory();

    const auth0 = useAuth0();

    const putUrl = buildUrl(API.PUT_LEAD_CONVERT_CLIENT, { id }, API_BASEURL); // build url2

    const [addToClientPopup, setAddToClientPopup] = useRecoilState(recAddToClientPopup);
    const [alreadyExistPopup, setAlreadyExistPopup] = useRecoilState(recAlreadyExistPopup);
    useEffect(() => {
        let mounted = true;
        const convertToClient = async () => {
            const [result, error] = await fetcherPut(putUrl, await getToken(auth0), { email });
            if (mounted) {
                processResponse(error, NOTIFY_LEAD_CONVERTED);
                setAddToClientPopup(POPUP_STATE.INIT);

                if ((error || !result.success) && error.code === 409) {
                    setAlreadyExistPopup({ ...alreadyExistPopup, id: error.id, showPopup: true });
                    // openNotification(NOTIFY_CONTACT_CREATED.AE);
                } else if (result && successTrigger) successTrigger();
                else if (result) history.push(buildUrl(ROUTES.SINGLE_CLIENTS, { id }));
            }
        };

        if (addToClientPopup.answered) convertToClient();

        return () => {
            mounted = false;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addToClientPopup]);
};
