import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { API, API_BASEURL, buildUrl, POPUP_STATE, ROUTES } from "../config";
import { recDeleteLeadPopup } from "../config/recoil/atoms";
import { fetcherDelete, NOTIFY_LEAD_DELETED } from "../utils";
import { getToken } from "../utils/auth";
import { processResponse } from "../utils/error";

export const useDeleteLead = (id: string, successTrigger?: Function) => {
    const history = useHistory();

    const auth0 = useAuth0();

    const [deleteLeadPopup, setDeleteLeadPopup] = useRecoilState(recDeleteLeadPopup);

    const deleteUrl = buildUrl(API.DELETE_LEAD, { id }, API_BASEURL); // build delete url

    useEffect(() => {
        let mounted = true;
        const deleteLead = async () => {
            const [result, error] = await fetcherDelete(deleteUrl, await getToken(auth0), "");
            if (mounted) {
                processResponse(error, NOTIFY_LEAD_DELETED);
                setDeleteLeadPopup(POPUP_STATE.INIT);
                if (result && successTrigger) successTrigger();
                if (result) history.push(ROUTES.LEADS);
            }
        };

        if (deleteLeadPopup.answered) deleteLead();

        return () => {
            mounted = false;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteLeadPopup]);
};
