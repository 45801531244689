import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { fetcherGetDownload, NOTIFY_REPORT_GENERATED } from "../utils";
import { getToken } from "../utils/auth";
import fileDownload from "js-file-download";
import { processResponse } from "../utils/error";

export const useDownloadDocument = (
    onSuccessTrigger?: () => void
): [(val: string) => void, (val: string) => void, Function, boolean] => {
    const auth0 = useAuth0();

    const [isLoading, setLoading] = useState(false); // Used for loading indicator while saving
    const [url, setUrl] = useState(""); // sets the document key
    const [filename, setFileName] = useState(""); // Used for loading indicator while saving

    const triggerDownload = () => {
        if (!isLoading) setLoading(true);
    };

    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            const [result, error] = await fetcherGetDownload(url, await getToken(auth0));

            processResponse(error, NOTIFY_REPORT_GENERATED);

            if (result && mounted) {
                const blob = await result.blob();
                fileDownload(blob, filename);
                if (onSuccessTrigger) onSuccessTrigger();
            }

            setLoading(false); // stop loading indicator for either case.
        };

        if (isLoading && url) fetchData();

        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, url]);

    return [setUrl, setFileName, triggerDownload, isLoading];
};
