import { LogoutIcon, PersonCircle } from "../../../icons";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export type UserMenuProps = {
    toggled: boolean;
    hide?: boolean;
};

export const UserMenu = ({ toggled, hide }: UserMenuProps) => {
    const { logout } = useAuth0();

    return (
        <div
            className={`${
                toggled ? "top-[-20px]" : "top-[-300px] opacity-0"
            } flex flex-col justify-around absolute left-[-100px] sm:left-0 right-0  ${
                !hide ? "h-[156px]" : "h-[100px]"
            } pb-2 bg-white rounded-br-[15px] rounded-bl-[15px] shadow-md z-[-1] transition-all duration-200`}
        >
            <div className="h-[100px]" />
            {!hide && (
                <Link to="/profile" tabIndex={toggled ? 0 : -1}>
                    <div className="flex py-2 hover:bg-gray-100 w-full">
                        <div className="flex w-7/12 m-auto justify-start text-reyna-text-4 font-inter text-r-medium-small-2 gap-2 items-center">
                            <PersonCircle className="fill-current w-[20px]" />
                            Profile
                        </div>
                    </div>
                </Link>
            )}
            {/* <Link to="/settings" tabIndex={toggled ? 0 : -1}>
                <div className="flex items-center py-2 hover:bg-gray-100 w-full">
                    <div className="flex w-7/12 m-auto justify-start text-reyna-text-4 font-inter text-r-medium-small-2 gap-2 items-center">
                        <SettingsIcon className="fill-current w-[20px]" />
                        Settings
                    </div>
                </div>
            </Link> */}

            <div
                className="flex items-center py-2 hover:bg-gray-100 w-full"
                onClick={() =>
                    logout({
                        returnTo: process.env.REACT_APP_URL || "https://crm.lareynadelcredito.com",
                    })
                }
            >
                <div className="flex w-7/12 m-auto justify-start text-reyna-text-4 font-inter text-r-medium-small-2 gap-2 items-center">
                    <LogoutIcon className="fill-current w-[20px]" />
                    Logout
                </div>
            </div>
        </div>
    );
};
