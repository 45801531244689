export const SINGLE_CLIENT_FORM = {
    MAX_VIOLATIONS: 3,
};
export const BASE_URL_IMAGE = "https://crm-lareyna-images.s3.eu-west-1.amazonaws.com/";

export const POPUP_STATE = {
    INIT: { isLoading: false, showPopup: false, answered: false },
    OPEN: { isLoading: false, showPopup: true, answered: false },
    ANSWER_TRUE: { isLoading: true, showPopup: true, answered: true },
    ANSWER_FALSE: { isLoading: false, showPopup: false, answered: false },
};

export const TABLE_PAGE_SIZE = 10;

export const INFINITE_SCROLL_PAGE_SIZE = 5;

