import { useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../config";
import { NOTIFY_NOT_FOUND, openNotification } from "../utils";

/**
 *
 * @returns a functiom to call when something is not found. you can pass
 * a route in the function. If a route is passed it will navigate to that route,
 * else it will navigate to the home page.
 */
export const useNotFound = () => {
    const history = useHistory();

    const goTo = useRef(history.push).current;

    const handleNotFound = useCallback(
        (fallbackPath: string = ROUTES.HOME) => {
            openNotification(NOTIFY_NOT_FOUND);
            goTo(fallbackPath);
        },
        [goTo]
    );

    return handleNotFound;
};
