import { useRef, useState } from "react";
import { API, API_BASEURL, SWR_CONFIG, buildUrl } from "../../../config";
import useGetSWR from "../../../hooks/useGetSWR";
import { CalendarLogo, MailIconFilled, NotificationIcon } from "../../../icons";
import { NotificationListRes } from "../../../types";
import NotificationSlideOver from "./NotificationsSlideover";

const Notifications = () => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [toggled, setToggled] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const url = buildUrl(API.NOTIFICATIONS_UNREAD, {}, API_BASEURL);

    const { data: notifications } = useGetSWR<NotificationListRes>(url, {
        ...SWR_CONFIG.LIST,
    });
    /**
     *  check if there is any new notification
     * @returns
     */
    const newNotifications = (type?: "conversation" | "appointment") => {
        if (!notifications?.count) return false;
        if (type) return notifications.notification?.some((n) => n.category === type);
        else
            return notifications.notification?.some(
                (n) => n.category !== "conversation" && n.category !== "appointment"
            );
    };

    const onClick = (value: boolean) => {
        setDisabled(true);
        setToggled(value);
        setTimeout(() => setDisabled(false), 500);
    };

    const [type, setType] = useState<"" | "conversation" | "appointment">("");

    return (
        <div
            ref={wrapperRef}
            className="flex flex-col items-center justify-center relative cursor-pointer z-10"
        >
            <div className="flex flex-row">
                <div
                    onClick={() => {
                        if (!disabled) {
                            setToggled(true);
                            setType("");
                        }
                    }}
                    className={"relative"}
                >
                    {newNotifications() && (
                        <span className="flex h-3 w-3 absolute right-0">
                            <span className="animate-ping-slow absolute inline-flex h-full w-full rounded-full bg-reyna-secondary opacity-75 "></span>
                            <span className="relative inline-flex rounded-full h-3 w-3 bg-reyna-secondary border-2 md:border-white border-reyna-primary"></span>
                        </span>
                    )}
                    <NotificationIcon className="md:text-reyna-primary text-white fill-current" />
                </div>
                <div
                    onClick={() => {
                        if (!disabled) {
                            setToggled(true);
                            setType("conversation");
                        }
                    }}
                    className={"relative ml-4"}
                >
                    {newNotifications("conversation") && (
                        <span className="flex h-3 w-3 absolute right-0">
                            <span className="animate-ping-slow absolute inline-flex h-full w-full rounded-full bg-reyna-secondary opacity-75 "></span>
                            <span className="relative inline-flex rounded-full h-3 w-3 bg-reyna-secondary border-2 md:border-white border-reyna-primary"></span>
                        </span>
                    )}
                    <MailIconFilled className="md:text-reyna-primary text-white fill-current" />
                </div>
                <div
                    onClick={() => {
                        if (!disabled) {
                            setToggled(true);
                            setType("appointment");
                        }
                    }}
                    className={"relative ml-4 flex items-center"}
                >
                    {newNotifications("appointment") && (
                        <span className="flex h-3 w-3 absolute right-0">
                            <span className="animate-ping-slow absolute inline-flex h-full w-full rounded-full bg-reyna-secondary opacity-75 "></span>
                            <span className="relative inline-flex rounded-full h-3 w-3 bg-reyna-secondary border-2 md:border-white border-reyna-primary"></span>
                        </span>
                    )}
                    <CalendarLogo className="md:text-reyna-primary text-white fill-current h-[20px]" />
                </div>
            </div>
            <NotificationSlideOver open={toggled} setOpen={onClick} type={type} />
        </div>
    );
};

export default Notifications;
