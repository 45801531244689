import image from "../../assets/img/something-went-wrong.png";
/**
 * This component display the 404 page.
 *
 */
export const NotFound = () => {
    return (
        <div className="w-full h-screen flex justify-center items-center px-5 pt-8">
            <div className="flex justify-center items-center space-y-8 flex-col text-center">
                <img src={image} className="w-60 h-full self-center" alt="something went wrong" />
                <h2 className="text-reyna-primary text-3xl">Oops!</h2>
                <p className="text-reyna-primary text-2xl">
                    We can't seem to find the page you're looking for.
                </p>
            </div>
        </div>
    );
};
