export const capitalizeFirstLetter = (val: string) => {
    return val.charAt(0).toUpperCase() + val.slice(1);
};

export const titlePrettifier = (val: string) => {
    return capitalizeFirstLetter(val).replaceAll("_", " ");
};

// Maximum of 20 results while previewing the report
export const REPORT_LIMIT = 20;

/**
 * Note this value is sensitive changing it will cause errors
 */
export const SELECT_LEADS = "lead";
/**
 * Note this value is sensitive changing it will cause errors
 */
export const SELECT_CLIENTS = "client";

const LEAD_OVERALL_ATTRIBURES = [
    "first_name",
    "last_name",
    "email",
    "phone",
    "birthday",
    "social_security_number",
    "itin",
    "own_rent",
    "mortgage_payment",
    "address",
    "city",
    "state",
    "zip",
    "status",
    "signup_date",
    "payment_type",
    "goal",
    "program",
    "quote",
    "preferred_language",
    "gender"
];

const LEAD_ATTRIBURES = [
    "source",
    "source_details",
    "receive_email",
    "receive_sms",
    "red_flag",
    "agent_id",
    "office_location",
    "created_at",
];

const CLIENT_ATTRIBUTES = [
    "red_flag",
    "receive_sms",
    "receive_email",
    "source",
    "source_details",
    "notes",
    "created_at",
    "agent_id",
    "office_location",
];

const CLIENT_OVERALL_ATTRIBUTES = [
    "first_name",
    "last_name",
    "email",
    "phone",
    "birthday",
    "social_security_number",
    "itin",
    "preferred_language",
    "own_rent",
    "mortgage_payment",
    "address",
    "city",
    "state",
    "zip",
    "status",
    "verification_date",
    "verification",
    "contract_open_date",
    "contract_close_date",
    "payment_type",
    "goal",
    "program",
    "credit_bureau_last",
    "accounts_positive",
    "accounts_negative",
    "before_score_eq",
    "before_score_tu",
    "before_score_ex",
    "after_score_eq",
    "after_score_tu",
    "after_score_ex",
    "gender"
];

const CLIENT_VIOLATION_ATTRIBUTES = ["type", "is_paid", "date"];

const CLIENT_ACCOUNT_ATTRIBUTES = [
    "name",
    "status",
    "experia",
    "transuni",
    "equifa",
    "deleted",
    "notes",
];

const CLIENT_BANKRUPTCY_ATTRIBUTES = [
    "taxes1",
    "taxes2",
    "qstionare",
    // "class_cert",
    "bank_statements",
    "discharged_date",
    "hearing_date",
    "proof_of_income",
    "dismissed_closed",
];

const CLIENT_PAYMENT_ATTRIBUTES = [
    "id",
    "start_date",
    "total_amount",
    "total_balance",
    "initial_deposit",
    "monthly_payment",
    "payment_option",
    "routing_number",
    "account_number",
    "cc",
    "code",
    "cc_ex",
];

const CLIENT_PAYMENT_TRANSACTIONS_ATTRIBUTES = ["date", "amount", "notes"];

const CLIENT_PROCESSING_ATTRIBUTES = [
    "idsocut_date",
    "credit_report_date",
    "financial_disclosure",
    "borrower_authorization",
    "audit_date",
    "audit_results",
    "verification_time",
];

const CLIENT_PROCESS_ATTRIBUTES = ["date", "ex1", "tu1", "eq1", "ex1_info", "tu1_info", "eq1_info"];

const CLIENT_SETTLEMENT_ATTRIBUTES = [
    "account_name",
    "balance",
    "settlement",
    "saving",
    "due_date",
    "status",
    "experia",
    "transuni",
    "equifa",
    "notes",
    "confirmation_number"
];

const CLIENT_CAR_ATTRIBUTES = ["value", "notes"];

const CLIENT_DEPENDANT_ATTRIBUTES = ["age"];

const CLIENT_FINANCIAL_DISCLOSURE_ATTRIBUTES = [
    "financial_employeer",
    "yearly_income",
    "household_income",
    "legal_status",
    "own_rent",
    "payment_type",
    "insurance_type",
    "carrier_type",
    "retirement_age",
    "life_insurance_payment",
    "insurance_carrier",
    // "life_insurance",
    "rate_on_mortgage",
    "last_tx_year",
    "last_tx_amount",
    "employee_type",
    "years_job",
    "pension_plan_401k",
    "life_ins_150k",
    "accepted",
    "debt",
    "tax_rev",
    "home_loan",
    "re",
    "cr",
    "bk",
    "life",
    "retirement",
    "college_fund",
    "home_rent",
    "car_sav",
    "fraud_alert",
    "notes",
];

export const TABLE_ATTRIBUTES = {
    [SELECT_LEADS]: { lead: LEAD_ATTRIBURES, lead_overall: LEAD_OVERALL_ATTRIBURES },
    [SELECT_CLIENTS]: {
        client: CLIENT_ATTRIBUTES,
        client_overall: CLIENT_OVERALL_ATTRIBUTES,
        client_financial_disclosure: CLIENT_FINANCIAL_DISCLOSURE_ATTRIBUTES,
        client_violations: CLIENT_VIOLATION_ATTRIBUTES,
        client_account: CLIENT_ACCOUNT_ATTRIBUTES,
        client_bankruptcy: CLIENT_BANKRUPTCY_ATTRIBUTES,
        client_payment: CLIENT_PAYMENT_ATTRIBUTES,
        client_payment_transactions: CLIENT_PAYMENT_TRANSACTIONS_ATTRIBUTES,
        client_processing: CLIENT_PROCESSING_ATTRIBUTES,
        client_process: CLIENT_PROCESS_ATTRIBUTES,
        client_settlement: CLIENT_SETTLEMENT_ATTRIBUTES,
        client_car: CLIENT_CAR_ATTRIBUTES,
        client_dependant: CLIENT_DEPENDANT_ATTRIBUTES,
    },
};

export const INIT_LEADS_REDUCER = {
    lead: LEAD_ATTRIBURES,
    lead_overall: LEAD_OVERALL_ATTRIBURES,
};

export const INIT_CLIENTS_REDUCER = {
    client: CLIENT_ATTRIBUTES,
    client_overall: CLIENT_OVERALL_ATTRIBUTES,
    client_financial_disclosure: CLIENT_FINANCIAL_DISCLOSURE_ATTRIBUTES,
    client_violations: CLIENT_VIOLATION_ATTRIBUTES,
    client_account: CLIENT_ACCOUNT_ATTRIBUTES,
    client_bankruptcy: CLIENT_BANKRUPTCY_ATTRIBUTES,
    client_payment: CLIENT_PAYMENT_ATTRIBUTES,
    client_payment_transactions: CLIENT_PAYMENT_TRANSACTIONS_ATTRIBUTES,
    client_processing: CLIENT_PROCESSING_ATTRIBUTES,
    client_process: CLIENT_PROCESS_ATTRIBUTES,
    client_settlement: CLIENT_SETTLEMENT_ATTRIBUTES,
    client_car: CLIENT_CAR_ATTRIBUTES,
    client_dependant: CLIENT_DEPENDANT_ATTRIBUTES,
};
