/**
 * Same as NumberFormatRFH but without react-hook-form control/register, using
 * value and onChange.
 */
import NumberFormat from "react-number-format";

type InputProps = {
    name: string;
    title?: string;
    placeholder?: string;
    className?: string;
    inputClassName?: string;
    titleClassname?: string;
    prefix?: string;
    suffix?: string;
    editing?: boolean;
    isDisabled?: boolean;
    children?: JSX.Element | false | undefined;
    decimalScale?: number;
    onChange?: (value: string) => void;
    value?: string | number | null | undefined;
};

export const NumberFormatInput = ({
    name,
    title,
    placeholder,
    className,
    inputClassName,
    titleClassname,
    editing = true,
    prefix,
    suffix,
    children,
    decimalScale = 2,
    onChange,
    value,
}: InputProps) => {
    return (
        <div className={`flex  ${className ? className : "flex-col"}`}>
            {title && (
                <div className={`text-r-medium-small-2 ${titleClassname ? titleClassname : ""}`}>
                    <span className="block overflow-y overflow-x-hidden break-words">{title}</span>
                </div>
            )}
            <div className="w-full">
                <NumberFormat
                    name={name}
                    value={value}
                    thousandSeparator={true}
                    thousandsGroupStyle="thousand"
                    decimalScale={decimalScale}
                    fixedDecimalScale
                    prefix={prefix}
                    suffix={suffix}
                    onChange={(val: any) =>
                        onChange && onChange(val.target.value.replace(/["$",",","(",")"]/gi, ""))
                    }
                    onBlur={() => {}}
                    onBlurCapture={() => {}}
                    onChangeCapture={() => {}}
                    onPointerLeave={() => {}}
                    isNumericString
                    placeholder={placeholder}
                    disabled={!editing}
                    className={`outline-none w-full text-reyna-primary border-reyna-grey-1 text-r-medium-small-2 focus:ring-reyna-primary
                       focus:border-reyna-primary transition-all rounded-[7px] py-1 px-2 mb-4 h-[38px]
                       ${inputClassName ? inputClassName : " bg-white"} ${
                        editing === false ? " bg-reyna-grey-3 " : ""
                    } `}
                />
                {/* You can pass a component to display stuff under the input. Its usually used for
                 displaying error msges */}
                {children && children}
            </div>
        </div>
    );
};
