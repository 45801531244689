import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { API, API_BASEURL, buildUrl, POPUP_STATE, ROUTES } from "../config";
import { recDeleteClientPopup } from "../config/recoil/atoms";
import { fetcherDelete, NOTIFY_CLIENT_DELETED } from "../utils";
import { getToken } from "../utils/auth";
import { processResponse } from "../utils/error";

export const useDeleteClient = (id: string, successTrigger?: Function) => {
    const history = useHistory();

    const auth0 = useAuth0();

    const [deleteClientPopup, setDeleteClientPopup] = useRecoilState(recDeleteClientPopup);

    const deleteUrl = buildUrl(API.DELETE_CLIENT, { id }, API_BASEURL); // build delete url

    useEffect(() => {
        let mounted = true;
        const deleteClient = async () => {
            const [result, error] = await fetcherDelete(deleteUrl, await getToken(auth0), "");

            if (mounted) {
                processResponse(error, NOTIFY_CLIENT_DELETED);
                setDeleteClientPopup(POPUP_STATE.INIT);
                if (result && successTrigger) successTrigger();
                if (result) history.push(ROUTES.CLIENTS);
            }
        };

        if (deleteClientPopup.answered) deleteClient();

        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteClientPopup]);
};
