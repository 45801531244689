import { API, API_BASEURL, buildUrl, SWR_CONFIG } from "../../config";
import useGetSWR from "../../hooks/useGetSWR";
import { IlanguageRes } from "../../types";

interface Iprops {
    languageId: string;
}

export const PreferredLanaguge = ({ languageId }: Iprops) => {
    const url = buildUrl(API.ALL_LANGUAGES, {}, API_BASEURL);

    const { data: languageListRes } = useGetSWR<IlanguageRes>(url, SWR_CONFIG.LIST);

    if (languageListRes?.language) {
        return <span>{languageListRes.language.find((lang) => lang.id === languageId)?.name}</span>;
    }

    return <></>;
};
