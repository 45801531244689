import { API, API_BASEURL, buildUrl, SWR_CONFIG } from "../../config";
import useGetSWR from "../../hooks/useGetSWR";
import Select from "react-select";
import { useEffect, useState } from "react";
import { IContactSearch, IContactSearchRes } from "../../types";
import { CustomSelect } from "./genericDropdowns/CustomSelect";

interface iSelect {
    label: string;
    value: string;
}

type InputProps = {
    title?: string;
    name?: string;
    onChangeFunction: (arg0: iSelect) => void;
    className?: string;
    inputClassName?: string;
    titleClassname?: string;
    editing?: boolean;
    authO?: boolean;
    textBoxClass?: string;
    placeholder?: string;
    value: iSelect | null;
    selectStyles?: any;
    children?: JSX.Element | false | undefined;
    isAgent?: boolean;
    isClearable?: boolean;
};

export const DropDownSearchUsers = ({
    title,
    onChangeFunction,
    className,
    value,
    name,
    editing = true,
    titleClassname,
    selectStyles,
    placeholder,
    children,
    isAgent,
    isClearable = true
}: InputProps) => {
    /**
     * Used for setting the url. for every input change the url is changed
     */
    const [url, setUrl] = useState("");
    /**
     * A list of suggestions from the db based on the user input
     */
    const [suggestions, setSuggestions] = useState<iSelect[]>([]);
    /**
     * Get the suggestions from db
     */
    const { data, isValidating } = useGetSWR<IContactSearchRes>(url, SWR_CONFIG.FORMS);
    /**
     *  Used for autocomplete. This function is called
     *  for every change in the search input field
     */
    const handleAutoComplete = (e: any) => {
        console.log(e);
        setUrl(
            buildUrl(API.CONTACT_SEARCH_ALL, {}, API_BASEURL, {
                keyword: e,
                isAgent: isAgent === true ? isAgent : "",
            })
        );
    };
    /**
     * Used to get the full name from the contact object
     */
    const getFullName = (n: IContactSearch) => `${n.first_name} ${n.last_name}`;
    /**
     * Everytime new data is fetched from the db, format it for react select
     */
    useEffect(() => {
        console.log(data);
        if (data?.names?.length) {
            const { names } = data;
            const temp = names.map((n) => ({ label: getFullName(n), value: n.person_id }));
            setSuggestions(temp);
        }
    }, [data]);

    /**
     * Used for autocomplete. When a user clicks
     * on a suggestion, this function handles it
     */
    const handleSearchChange = (e: any) => onChangeFunction(e);

    return (
        <div className={className}>
            {title && (
                <div
                    className={`whitespace-nowrap text-r-medium-small-2 ${
                        titleClassname ? titleClassname : ""
                    }`}
                    data-testid={`${name}-test`}
                >
                    {title}
                </div>
            )}
            <Select
                isDisabled={!editing}
                isLoading={isValidating}
                value={value}
                onInputChange={handleAutoComplete}
                onChange={handleSearchChange}
                isSearchable={true}
                options={suggestions}
                menuShouldScrollIntoView={false}
                components={
                    {
                        // DropdownIndicator: () => null,
                        // IndicatorSeparator: () => null,
                    }
                }
                noOptionsMessage={() => null}
                styles={selectStyles}
                className="react-select"
                placeholder={placeholder}
                name={name}
                isClearable={isClearable}
            />
            {children}
            {/* <CustomSelect
                title={title}
                placeholder={placeholder}
                name={name}
                onChangeFunction={handleSearchChange}
                className={className ? className : "flex flex-col mb-4"}
                titleClassName={titleClassname ? titleClassname : "text-r-medium-small-2"}
                editing={editing}
                value={getValue()}
                options={suggestions}
                containerClassname=""
                isObject
                selectStyles={selectStyles}
            >
                {children}
            </CustomSelect> */}
        </div>
    );
};
