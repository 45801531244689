// The fectcher functions used for swr.
export const fetcherGet = async (url: string, token: string) => {
    const headers = token ? {
        Authorization: "Bearer " + token,
    } : undefined;
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: headers,
        });
        if (!response.ok) {
            throw response;
        }

        const results = await response.json();

        // If our backend throwed an error it will be found here.
        if (results.error) {
            throw results;
        }

        return results;
    } catch (error: any) {
        throw error;
    }
};

export const fetcherGetDownload = async (url: string, token: string) => {
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
            },
        });

        if (!response.ok) {
            throw response;
        }

        return [response, null];
    } catch (error: any) {
        return [null, error];
    }
};

export const fetcherPut = async (url: string, token: string, data: any): Promise<[any, any]> => {
    try {
        const response = await fetch(url, {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw response;
        }

        const results = await response.json();

        // If our backend throwed an error it will be found here.
        if (results.error) {
            throw results;
        }

        return [results, null];
    } catch (error: any) {
        return [null, error];
    }
};
export const fetcherUploader = async (
    url: string,
    token: string,
    body: FormData
): Promise<[any, any]> => {
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                // "Content-Type": "multipart/form-data",
            },
            body,
        });

        if (!response.ok) {
            throw response;
        }

        const results = await response.json();

        // If our backend throwed an error it will be found here.
        if (results.error) {
            throw results;
        }

        return [results, null];
    } catch (error: any) {
        return [null, error];
    }
};
export const fetcherDelete = async (
    url: string,
    token: string,
    body: string
): Promise<[any, any]> => {
    try {
        const response = await fetch(url, {
            method: "DELETE",
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
            body,
        });
        if (!response.ok) {
            throw response;
        }

        const results = await response.json();

        // If our backend throwed an error it will be found here.
        if (results.error) {
            throw results;
        }

        return [results, null];
    } catch (error: any) {
        return [null, error];
    }
};
export const fetcherPost = async (url: string, token: string, data: any): Promise<[any, any]> => {
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        if (!response.ok) {
            throw response;
        }

        const results = await response.json();

        // If our backend throwed an error it will be found here.
        if (results.error) {
            throw results;
        }

        return [results, null];
    } catch (error: any) {
        return [null, error];
    }
};
