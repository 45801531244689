import { useEffect } from "react";
import { API_BULK, API_BULK_BASEURL, buildUrl, SWR_CONFIG } from "../../config";
import useGetSWR from "../../hooks/useGetSWR";
import { iReport, IReportsRes } from "../../types";
import { CustomSelect } from "./genericDropdowns/CustomSelect";

type InputProps = {
    title?: string;
    placeholder?: string;
    name?: string;
    onChangeFunction?: (arg0: string) => void;
    className?: string;
    inputClassName?: string;
    titleClassname?: string;
    editing?: boolean;
    textBoxClass?: string;
    value?: string | number;
    handleReportsData: (data: iReport[]) => void;
};

export const DropDownReports = ({
    title,
    placeholder = "",
    name = "",
    onChangeFunction,
    className,
    inputClassName,
    titleClassname,
    editing,
    textBoxClass,
    value = "",
    handleReportsData,
}: InputProps) => {
    const url = buildUrl(API_BULK.REPORTS_ALL, {}, API_BULK_BASEURL);

    const { data: reportsRes } = useGetSWR<IReportsRes>(url, SWR_CONFIG.FORMS);

    useEffect(() => {
        if (reportsRes?.reports) handleReportsData(reportsRes?.reports);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportsRes]);
    const onChange = (val: any, action: any) => {
        if (!onChangeFunction) return "";

        if (action.action === "clear") onChangeFunction("");
        else onChangeFunction(val.value);
    };

    const toLabelValue = () => {
        const options = [];
        if (!reportsRes) return [];
        for (const report of reportsRes?.reports) {
            options.push({ value: report.id, label: report.name });
        }
        return options;
    };

    const getValue = () => {
        if (!reportsRes) return "";
        const report = reportsRes?.reports.find((r) => r.id === value);
        if (!report) return "";
        return { label: report.name, value: value };
    };
    return (
        <CustomSelect
            title={title}
            placeholder={placeholder}
            name={name}
            selectStyles={selectStyles}
            onChangeFunction={onChange}
            containerClassname="capitalize"
            className={className ? className : "w-full flex flex-col mb-4"}
            titleClassName={titleClassname ? titleClassname : "text-r-medium-small-2 w-full"}
            editing
            isObject
            value={getValue()}
            options={toLabelValue()}
        />
    );
};

const selectStyles = (editing: boolean) => {
    return {
        control: (state: any) => ({
            border: "1px solid rgba(228, 229, 232, 1)",
            color: !editing ? "rgba(31, 41, 55, 1)" : "rgba(0, 0, 0, 1)",
            display: "flex",
            outline: "2px solid transparent",
            outlineOffset: "2px",
            borderRadius: "7px",
            backgroundColor: "white",
            fontSize: "14px",
            height: "40px",
        }),
        dropdownIndicator: (provided: any) => ({
            ...provided,
            padding: "8px",
        }),
        indicatorSeparator: () => ({
            display: "none",
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            padding: "0 8px",
        }),
    };
};
