import { io, Socket } from "socket.io-client";

export interface MessageEditing {
    isEditing: boolean;
    contactId: string;
    data: any;
}

export interface MessageNotification {
    action: MessageNotificationAction;
    userId: string;
    contactId: string;
}

export enum MessageNotificationAction {
    "init_editing",
    "cancel_editing",
    "save_editing",
    "upload_file",
    "remove_file",
    "add_note",
}

let socket: Socket;
// const url = "https://api-stg.crm.lareynadelcredito.com/realtime";
const url = process.env.REACT_APP_SOCKET_API_URL || "";


/**
 * This method inits the socket communication
 * @param room Room the user has to be added into
 * @param userId The user id of the current logged in user
 */
export const initiateSocket = (room: string, userId: string) => {
    const path = process.env.NODE_ENV !== "development" ? "/realtime/socket.io" : "/socket.io";
    if (!socket) {
        socket = io(url, {
            withCredentials: true,
            rejectUnauthorized: false,
            secure: true,
            query: {
                userId,
            },
            transports: ["websocket"],
            upgrade: false,
            path,
        });
        // console.log(`Connecting socket...`);
        if (socket) {
            socket.connect();
            // socket.emit("join", room);

            socket.on("disconnect", () => {
                console.log(socket.id); // undefined
            });
        }
    }

};

/**
 * Join a room by id
 */
export const joinRoom = (room: string) => {
    // socket.join(room);
    socket.emit("join-room", room);
};

/**
 * Leave a room by id
 */
export const leaveRoom = (room: string) => {
    // socket.join(room);
    socket.emit("leave-room", room);
};

/**
 * Force socket disconnection
 */
export const disconnectSocket = (id?: string) => {
    if (socket) socket.disconnect();
};

/**
 * This method subcribes socket to receive notification messages
 * @param cb Callback function after receiving the message
 */
export const subscribeToNotification = (cb: Function) => {
    if (!socket) return;
    socket.on("message-notification", (msg) => {


        // cb(null, msg);
    });
};

/**
 * This method subscribes socket to receive editing messages
 * @param cb Callback function after receiving the message
 */
export const subscribeToEditing = (cb: Function) => {
    if (!socket) return;
    socket.on("message-editing", (msg) => {

        cb(msg);
    });
};

/**
 * This method subscribes socket to receive viewing messages
 * @param cb Callback function after receiving the message
 */
export const subscribeToViewing = (cb: Function) => {
    if (!socket) return;
    socket.on("message-viewing", (msg) => {


        // cb(msg, socket.io.engine.opts.query.userId);
    });
};

/**
 * This method subscribes socket to receive editing messages
 * @param cb Callback function after receiving the message
 */
export const unsubscribeToEditing = () => {
    if (!socket) return;
    socket.off("message-editing");
};

/**
 * This method subscribes socket to receive viewing messages
 * @param cb Callback function after receiving the message
 */
export const unsubscribeToViewing = () => {
    if (!socket) return;
    socket.off("message-viewing");
};

/**
 * Send editing message when someone enters into editing page
 * @param message The message sent to the server
 */
export const sendEditing = (message: MessageEditing) => {

    if (socket?.connected) {
        socket.emit("message-editing", message);
    }
};

/**
 * Send notification message when something happens (edited client/lead successfully)
 * @param message The message sent to the server
 */
export const sendNotification = (message: MessageNotification) => {
    if (socket) socket.emit("message-notification", message);
};


/**
 * This method subcribes socket to an event
 * @param cb Callback function after receiving the message
 */
export const subscribe = (ev: string, cb: Function) => {
    if (!socket) return;
    socket.on(ev, (msg) => {
        cb(msg)
    });
};

export const unsubscribe = (ev: string) => {
    if (!socket) return; socket.off(ev);
}

/**
 * Send notification message when something happens
 * @param message The message sent to the server
 */
export const sendMessage = (room: string, message: any) => {
    if (socket) socket.emit(room, message);
};

/**
 *
 *  PROGRAM VALIDATION FUNCTIONS 
 *
 */
export const newProgramValidation = (message: any) => {
    if (socket) socket.emit('new-program-validation', message)
}

export const processProgramValidation = (message: any) => {
    if (socket) socket.emit('process-program-validation', message)
}

export const finishProgramValidation = (message: any) => {
    if (socket) socket.emit('finish-program', message)
}

export const stopProcessProgramValidation = (message: any) => {
    if (socket) {
        console.log(socket)
        socket.emit('stop-process-program-validation', message, (res: any) => console.log(res))
    }
    else console.log('no socket')

}

export const validateProgramValidation = async (message: any) => {
    if (socket) socket.emit('validate-program-validation', message)
}

export const checkSocketConnected = () => {
    if (socket) return true;
    return false;
}