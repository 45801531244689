import { ActionMeta, ControlProps, CSSObjectWithLabel, MultiValue } from "react-select";
import Select from "react-select";

export type ReynaSelectProps = {
    title?: string;
    options: any[] | null;
    row?: boolean;
    className?: string;
    selectStyles?: any;
    titleClassName?: string;
    multi?: boolean;
    placeholder?: string;
    value?: any;
    onChangeFunction?: (newValue: MultiValue<never> | null, actionMeta: ActionMeta<never>) => void;
    editing?: boolean;
    name?: string;
    customContent?: boolean;
    searchable?: boolean;
    isClearable?: boolean;
    isObject?: boolean;
    children?: JSX.Element | false | undefined;
    inputClassname?: string;
    containerClassname: string;
    control?: any;
    textBlack?: boolean;
    readonly?: boolean;
    menuPosition?: "fixed";
};

export const CustomSelect = ({
    title,
    options,
    row,
    className,
    selectStyles,
    titleClassName,
    multi,
    placeholder,
    onChangeFunction,
    value,
    isObject = false,
    editing = false,
    name,
    customContent,
    searchable,
    menuPosition,
    isClearable = true,
    children,
    containerClassname,
    inputClassname,
    control,
    textBlack = false,
    readonly = false,
}: ReynaSelectProps) => {
    const handleinputs = ({ props, state, methods }: any) => {
        return props?.values?.length ? (
            <p>{`Selected ${props?.values?.length}`}</p>
        ) : (
            <p>{placeholder}</p>
        );
    };

    const adddtionalStuff = () => {
        if (customContent) {
            return { contentRenderer: handleinputs };
        }
        return {};
    };

    const defaultSelectStyles = () => {
        return {
            control: (state: any) => ({
                border: "1px solid rgba(228, 229, 232, 1)",
                color: !editing && !textBlack ? "rgba(31, 41, 55, 1)" : "rgba(0, 0, 0, 1)",
                display: "flex",
                outline: "2px solid transparent",
                outlineOffset: "2px",
                borderRadius: "7px",
                backgroundColor: !editing ? "rgba(248, 248, 248, 1)" : "",
                fontSize: "14px",
                textAlign: "initial",
                // height: "38px",
                ...control,
            }),
            dropdownIndicator: (provided: any) => ({
                ...provided,
                padding: "8px",
                visibility: readonly && "hidden",
                // color: !editing ? "hsl(0, 0%, 80%)" : "rgba(0, 0, 0, 1)",
            }),
            multiValueRemove: (provided: any) => ({
                ...provided,
                display: readonly && "none",
            }),
            // clearIndicator: (provided: any) => ({
            //     ...provided,
            //     color: !editing ? "hsl(0, 0%, 80%)" : "rgba(0, 0, 0, 1)",
            // }),
            indicatorSeparator: () => ({
                display: "none",
            }),
            valueContainer: (provided: any) => ({
                ...provided,
                padding: "0 8px",
            }),
            singleValue: (provided: any) => ({
                ...provided,
                color: !editing && !textBlack ? "hsl(0, 0%, 60%)" : "black",
                "padding-right": readonly && "6px",
            }),
            multiValue: (provided: any) => ({
                ...provided,
                "padding-right": readonly && "6px",
            }),
            indicatorsContainer: (provided: any) => ({
                ...provided,
                width: readonly && "0px",
            }),
        };
    };

    return (
        <div
            className={`flex ${row ? "flex-row items-center" : "flex-col"} ${className ? className : "gap-2"
                }`}
            data-testid={`${name}-test`}
        >
            {title && (
                <span
                    className={`${titleClassName
                            ? titleClassName
                            : "font-roboto font-medium text-sm text-reyna-grey-4 whitespace-nowrap"
                        }`}
                >
                    {title}
                </span>
            )}
            <div className={`w-full text-r-medium-small-2 ${containerClassname}`}>
                <Select
                    name={name}
                    styles={defaultSelectStyles() as any}
                    isDisabled={!editing || readonly}
                    className={inputClassname ? inputClassname : "react-select"}
                    menuPosition={menuPosition || undefined}
                    isClearable={isClearable}
                    maxMenuHeight={200}
                    options={options as never[]}
                    isLoading={options === null}
                    value={
                        value === "" || !value
                            ? null
                            : (!multi &&
                                ({
                                    value: isObject ? value.value : value,
                                    label: isObject ? value.label : value,
                                } as never)) ||
                            (value as never[])
                    }
                    placeholder={placeholder ? placeholder : "Select Option"}
                    isMulti={multi}
                    onChange={onChangeFunction ? onChangeFunction : () => { }}
                    {...adddtionalStuff()}
                    menuPlacement="auto"
                />
                {children}
            </div>
        </div>
    );
};
