import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { API, API_BASEURL, PERMISSIONS, SWR_CONFIG, buildUrl } from "../../config";
import { recRedFlagPopup } from "../../config/recoil/atoms";
import { usePermissions } from "../../hooks";
import useGetSWR from "../../hooks/useGetSWR";
import { FlagOutlined, FlagRed } from "../../icons";
import { iFlagRes } from "../../types";
import { NOTIFY_FLAG_SAVED, fetcherPut, notificationToShow, openNotification } from "../../utils";
import { getToken } from "../../utils/auth";

interface iProps {
    person_id: string;
    agent_email?: string;
    agent_name?: string;
    name?: string;
    url_lead?: string;
    flagFor: "client" | "lead";
    autocomplete?: boolean;
}

export const Flag = ({ person_id, flagFor, autocomplete = false }: iProps) => {
    const { checkPermission } = usePermissions();

    const checkPermissionFlag = () => {
        switch (flagFor) {
            case "client":
                return checkPermission(PERMISSIONS.CLIENT_UPDATE_FLAG);
            case "lead":
                return checkPermission(PERMISSIONS.LEAD_UPDATE_FLAG);
        }
    };
    const checkPermissionUnflag = () => {
        switch (flagFor) {
            case "client":
                return checkPermission(PERMISSIONS.CLIENT_UPDATE_UNFLAG);
            case "lead":
                return checkPermission(PERMISSIONS.LEAD_UPDATE_UNFLAG);
        }
    };

    const auth0 = useAuth0();

    const [isFlagged, setFlag] = useState(false);

    const [redFlagPopup, setRedFlagState] = useRecoilState(recRedFlagPopup);

    const isClient = flagFor === "client";

    const PATH = isClient ? API.CLIENT_FLAG : API.LEAD_FLAG; // Flag for client or lead

    const url = buildUrl(PATH, { id: person_id }, API_BASEURL); // build url to fetch forms

    const { data: flagRes } = useGetSWR<iFlagRes>(url, SWR_CONFIG.RED_FLAG); // Get Flag data

    useEffect(() => {
        if (flagRes) {
            const { client, lead } = flagRes;

            // Depending to which api the call was made to. Only one will be true

            if (client) setFlag(client.red_flag);

            if (lead) setFlag(lead.red_flag);
        }
    }, [flagRes]);

    useEffect(() => {
        let mounted = true;

        const updateFlag = async () => {
            const PUT_PATH = isClient ? API.PUT_CLIENT_FLAG : API.PUT_LEAD_FLAG;
            const putUrl = buildUrl(PUT_PATH, {}, API_BASEURL); // build update url
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [data, error] = await fetcherPut(putUrl, await getToken(auth0), {
                person_id,
                red_flag: !isFlagged,
                note: redFlagPopup.note,
            });

            if (mounted) {
                console.log(error);
                if (error?.code === 403) {
                    openNotification({
                        title: "Forbidden",
                        message: error?.error || "",
                        type: "warning",
                    });
                } else {
                    notificationToShow(error, NOTIFY_FLAG_SAVED);
                }
            }
            if (mounted && !error) setFlag(!isFlagged);
            if (mounted)
                setRedFlagState({ isLoading: false, showPopup: false, answered: false, note: "" });
        };

        if (redFlagPopup.answered) updateFlag();

        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redFlagPopup]);

    if (isFlagged) {
        return (
            <FlagRed
                onClick={() =>
                    !autocomplete &&
                    checkPermissionUnflag() &&
                    setRedFlagState({ ...redFlagPopup, showPopup: true })
                }
                className={`fill-current h-[20px] ${
                    !autocomplete && checkPermissionUnflag() && "cursor-pointer"
                }`}
            />
        );
    }

    return (
        <FlagOutlined
            onClick={() =>
                !autocomplete &&
                checkPermissionFlag() &&
                setRedFlagState({ ...redFlagPopup, showPopup: true })
            }
            className={`fill-current h-[20px] ${
                !autocomplete && checkPermissionFlag() && "cursor-pointer"
            }`}
        />
    );
};
