import { useEffect, useState } from "react";
import { API, API_BASEURL, buildUrl, SWR_CONFIG } from "../../config";
import useGetSWR from "../../hooks/useGetSWR";
import { CreditorListRes } from "../../types";
import { IEmployeeDropdownRes } from "../../types/EmployeeDropdown";
import { CustomSelect } from "./genericDropdowns/CustomSelect";

type InputProps = {
    title?: string;
    placeholder?: string;
    name?: string;
    onChangeFunction?: (arg0: string) => void;
    className?: string;
    inputClassName?: string;
    titleClassname?: string;
    outerClass?: string;
    editing?: boolean;
    authO?: boolean;
    textBoxClass?: string;
    value?: string | number;
    children?: JSX.Element | false | undefined;
    authCheck?: boolean;
    selectStyles?: any;
    disabledOptions?: string[]
};

export const DropDownCreditors = ({
    title,
    placeholder = "",
    name = "",
    onChangeFunction,
    className,
    inputClassName,
    titleClassname,
    editing,
    textBoxClass,
    selectStyles,
    value = "",
    authO,
    children,
    outerClass,
    disabledOptions,
}: InputProps) => {
    const [options, setOptions] = useState<null | any[]>(null);

    const url = buildUrl(API.CREDITOR_ALL, {}, API_BASEURL);
    const { data: creditorRes } = useGetSWR<CreditorListRes>(url, SWR_CONFIG.FORMS);

    const onChange = (val: any, action: any) => {
        if (!onChangeFunction) return "";

        if (action.action === "clear") onChangeFunction("");
        else onChangeFunction(val.value);
    };

    useEffect(() => {
        if (creditorRes?.creditor) {
            setOptions([
                ...creditorRes.creditor.map((c) => ({ label: c.name, value: c.id, phone: c.phone })),
            ]);
        }
    }, [creditorRes]);

    const getValue = () => {
        if (!options) return "";
        const creditor = options?.find((e) => value === e.value);
        if (!creditor) return "";
        return { ...creditor };
    };

    return (
        <div className={`w-full ${outerClass && outerClass}`}>
            <CustomSelect
                title={title}
                placeholder={""}
                name={name}
                onChangeFunction={onChange}
                className={className ? `flex flex-col w-full  ${className}` : "flex flex-col mb-4"}
                titleClassName={titleClassname ? titleClassname : "text-r-medium-small-2 mb-2"}
                editing={editing}
                value={getValue()}
                options={options}
                containerClassname=""
                isObject
                selectStyles={selectStyles}
            >
                {children}
            </CustomSelect>
        </div>
    );
};
