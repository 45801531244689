import Button from "../../components/buttons/Button";
import {
    ActivityLogo,
    CalendarLogo,
    ClientsLogo,
    ConversationsLogo,
    DeleteIcon,
    LeadsLogo,
} from "../../icons";
import InfiniteScroll from "react-infinite-scroll-component";
import { useState, useEffect, useRef } from "react";
import Loader from "react-loader-spinner";
import useGetSWR from "../../hooks/useGetSWR";
import { NotificationListRes, SingleNotification } from "../../types";
import { API, API_BASEURL, buildUrl, SWR_CONFIG } from "../../config";
import { useHistory } from "react-router";
import { fetcherPut, NOTIFY_NOTIFICATION, openNotification } from "../../utils";
import { getToken } from "../../utils/auth";
import { useAuth0 } from "@auth0/auth0-react";

type iType = {
    category: string;
};

/**
 * Shows the notification type (title and icon) for the category given
 * @param param0
 * @returns
 */
const NotificationType = ({ category }: iType) => {
    const template = (title: string, icon: any) => {
        return (
            <div className="bg-reyna-primary text-r-medium-small items-center text-white px-3 py-1 rounded-lg flex flex-row">
                {icon}
                <p className="ml-2">{title}</p>
            </div>
        );
    };

    const iconClass = "text-reyna-secondary fill-current w-[20px]";
    /**
     * Depending on the category, show its title and icon
     */
    switch (category) {
        case "lead":
            return template("Lead", <LeadsLogo className={iconClass} />);
        case "client":
            return template("Client", <ClientsLogo className={iconClass} />);
        case "task":
            return template("Task", <ActivityLogo className={iconClass} />);
        case "appointment":
            return template(
                "Appointment",
                <CalendarLogo className="text-reyna-secondary fill-current w-[15px]" />
            );
        case "conversation":
            return template("Conversation", <ConversationsLogo className={iconClass} />);
    }

    return <></>;
};

type iNotification = {
    message: string;
    created_at: Date;
    read: boolean;
    toggleRead: () => void;
    markAsRead: () => void;
    url: string;
    category: string;
};

/**
 * Single notification element
 * @param param0
 * @returns
 */
const Notification = ({
    message,
    created_at,
    url,
    read,
    toggleRead,
    category,
    markAsRead,
}: iNotification) => {
    const [hover, setHover] = useState(false);
    const history = useHistory();
    const [hoverTooltip, setHoverTooltip] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);

    /**
     * Handle on click for the nofitication
     * @param event
     * @returns
     */
    const onclick = (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            // click on notification
            markAsRead();
            if (url[0] !== "/") history.push("/" + url);
            else history.push(url);
            return;
        }
        // click on mark as read/unread
        toggleRead();
    };

    /**
     * Get date difference with current date
     * @param date
     * @returns
     */
    function dateDifference(date: Date) {
        let diffInMilliSeconds = Math.abs(date.getTime() - Date.now()) / 1000;
        // calculate days
        const days = Math.floor(diffInMilliSeconds / 86400);
        diffInMilliSeconds -= days * 86400;

        // calculate hours
        const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
        diffInMilliSeconds -= hours * 3600;

        // calculate minutes
        const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= minutes * 60;

        if (days > 30)
            return Math.floor(days / 30) + " month" + (Math.floor(days / 30) > 1 ? "s" : "");
        if (days > 0) return days + " day" + (days > 1 ? "s" : "");
        if (hours > 0) return hours + " hour" + (hours > 1 ? "s" : "");
        if (minutes > 0) return minutes + " minute" + (minutes > 1 ? "s" : "");
        return "Less than 1 minute";
    }

    return (
        <div
            className="bg-white rounded-lg py-7 px-10 flex flex-col my-3 hover:bg-reyna-grey-2 hover:bg-opacity-60 cursor-pointer"
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={(event) => onclick(event)}
        >
            <div className="flex flex-row justify-between items-center">
                <NotificationType category={category} />
                <div className="text-reyna-text-2 text-r-small-medium">
                    {dateDifference(new Date(created_at))}
                </div>
            </div>
            <div className="flex flex-row items-center justify-between pt-3">
                <div className="text-black">{message}</div>
                <div
                    className="flex flex-col items-center justify-center relative"
                    onMouseOver={() => setHoverTooltip(true)}
                    onMouseLeave={() => setHoverTooltip(false)}
                >
                    <div className="relative" ref={wrapperRef}>
                        <span
                            className={`relative rounded-full h-6 w-6 flex items-center justify-center
              ${hover ? " bg-reyna-grey-1" : " bg-transparent"}`}
                        >
                            {!read && (
                                <span className="relative inline-flex rounded-full h-2 w-2 bg-reyna-primary"></span>
                            )}
                        </span>
                    </div>
                    {hoverTooltip && (
                        <div className="absolute top-8 bg-reyna-primary text-r-small px-2 py-1 rounded-md text-white w-max">
                            Mark as {read ? "unread" : "read"}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

type iNotifications = {
    setOpen: (value: boolean) => void;
    type: "" | "conversation" | "appointment";
};

const NotificationScreen = ({ setOpen, type }: iNotifications) => {
    const auth0 = useAuth0();
    /**
     * States
     */
    const PAGE_STEP = 10;
    const [limit, setLimit] = useState(PAGE_STEP);
    const [max, setMax] = useState(-1);
    const [data, setData] = useState<SingleNotification[]>([]);
    const [onlyUnread, setOnlyunread] = useState(true);
    const [missedFetch, setMissedFetch] = useState(false);

    /**
     * Url for all notifications
     */
    const url = buildUrl(API.NOTIFICATIONS, {}, API_BASEURL, {
        limit: PAGE_STEP,
        offset: limit - PAGE_STEP,
        unread: onlyUnread,
        type,
    });
    /**
     * Get data from API
     */
    const {
        data: notifications,
        error,
        isValidating,
    } = useGetSWR<NotificationListRes>(url, {
        ...SWR_CONFIG.NOTIFICATIONS,
    });

    /**
     * Save notifications data
     */
    useEffect(() => {

        if (!isValidating && notifications?.notification) {
            // if (data.length > 0) setData([...data, ...notifications.notification]);
            // else
            if (limit === 10)
                setData((prev) => [...notifications.notification!]);
            else
                setData((prev) => [...prev, ...notifications.notification!]);
            if (max === -1) setMax(notifications.count || 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications]);

    /**
     * Fetch data for infinite scrolling
     */
    const fetchData = () => {
        if (!isValidating) {
            setLimit(limit + PAGE_STEP);
        } else {
            setMissedFetch(true);
        }
    };

    /**
     * When scrolling, and triggering fetchData, before the fetch finishes but
     * it has the cached data, the setLimit may be skipped. This effect makes
     * sure it is set when possible.
     */
    useEffect(() => {
        if (!isValidating && missedFetch) {
            setMissedFetch(false);
            setLimit(limit + PAGE_STEP);
        }
    }, [isValidating])

    /**
     * Mark notifications as read
     * @param index
     */
    const markAsRead = (index: number) => {
        toggleRead(index, true, false);
        setOpen(false);
    };

    /**
     * Toggle read value for a given notification
     * @param index
     * @param value
     * @param notify
     */
    const toggleRead = async (index: number, value?: boolean, notify?: boolean) => {
        const newData = [...data];
        newData[index].read = value !== undefined ? value : !newData[index].read;
        setData([...newData]);
        if (newData[index].read) {
            // mark as read
            const mark2lUrl = buildUrl(
                API.PUT_READ_NOTIFICATION,
                { id: newData[index].id },
                API_BASEURL,
                {}
            );
            await fetcherPut(mark2lUrl, await getToken(auth0), {});
            if (notify) openNotification(NOTIFY_NOTIFICATION.AS_READ);
        } else {
            // mark as unread
            const mark1Url = buildUrl(
                API.PUT_UNREAD_NOTIFICATION,
                { id: newData[index].id },
                API_BASEURL,
                {}
            );
            await fetcherPut(mark1Url, await getToken(auth0), {});
            if (notify) openNotification(NOTIFY_NOTIFICATION.AS_UNREAD);
        }
    };

    /**
     * Change tab resets limit and data
     * @param value
     */
    const onTabChange = (value: boolean) => {
        setLimit(PAGE_STEP);
        setOnlyunread(value);
        setData([]);
        setMax(-1);
    };

    /**
     * Mark all notifications as read
     */
    const markAllRead = async () => {
        const markallUrl = buildUrl(API.PUT_READ_ALL_NOTIFICATION, {}, API_BASEURL, { type });
        await fetcherPut(markallUrl, await getToken(auth0), {});
        openNotification(NOTIFY_NOTIFICATION.ALL);

        const newData = [...data];
        for (const n of newData) {
            n.read = true;
        }
        setData([...newData]);
    };

    return (
        <div className="w-full flex justify-center  bg-reyna-grey-1">
            <div className="flex flex-col gap-4 max-w-2xl w-full">
                <div className="flex flex-row items-center">
                    <div className="gap-x-5 flex flex-row items-center">
                        <span
                            className={`tab-link cursor-pointer ${onlyUnread ? "tab-link-active" : ""
                                }`}
                            onClick={() => !onlyUnread && onTabChange(true)}
                        >
                            Unread
                        </span>
                        <span
                            className={`tab-link cursor-pointer ${!onlyUnread ? "tab-link-active" : ""
                                }`}
                            onClick={() => onlyUnread && onTabChange(false)}
                        >
                            All
                        </span>
                    </div>
                    <div className="w-full flex justify-end">
                        <Button
                            Icon={DeleteIcon}
                            title="Mark All As Read"
                            className="self-start text-r-medium font-medium p-2 px-4 w-full sm:w-auto"
                            iconClass="w-[13px] h-[13px]"
                            clickFunction={markAllRead}
                        />
                    </div>
                </div>
                <div className="h-full">
                    {isValidating && data.length === 0 ? (
                        <div className="w-full flex items-center justify-center">
                            <Loader type="ThreeDots" color="#000000" height={48} width={48} />
                        </div>
                    ) : (
                        <InfiniteScroll
                            dataLength={data?.length || 0} //This is important field to render the next data
                            next={fetchData}
                            hasMore={(data?.length || 0) < max}
                            loader={
                                <div className="w-full flex items-center justify-center">
                                    <Loader
                                        type="ThreeDots"
                                        color="#000000"
                                        height={48}
                                        width={48}
                                    />
                                </div>
                            }
                            initialScrollY={0}
                            scrollableTarget="scrollTarget"
                            endMessage={
                                <p style={{ textAlign: "center" }}>
                                    <b>
                                        No more
                                        {type == "conversation"
                                            ? " message notification"
                                            : " notifications"}
                                    </b>
                                </p>
                            }
                        >
                            <div className="rounded-md">
                                {data?.map((n, index) => {
                                    return (
                                        <Notification
                                            key={n.id}
                                            message={n.message}
                                            created_at={n.created_at}
                                            read={n.read}
                                            toggleRead={() => toggleRead(index, undefined, true)}
                                            markAsRead={() => markAsRead(index)}
                                            url={n.url}
                                            category={n.category}
                                        />
                                    );
                                })}
                            </div>
                        </InfiniteScroll>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NotificationScreen;
