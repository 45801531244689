/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { buildUrl, ROUTES } from "../config";
import { contactPagination } from "../config/recoil/atoms";

function useCheckAll(tableData: { [p: string]: any }[], state?: any) {
    type ISort = {
        sortBy: string;
        sortDirection: string;
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [checkedAll, setCheckedAll] = useState(false);
    const [sort, setSort] = useState({
        sortBy: "",
        sortDirection: "",
    });
    const { search } = useLocation<any>();
    const query = new URLSearchParams(search);
    var page: any = query.get("page");
    var limitUrl: any = query.get("limit");
    const limits = ["10", "50", "100"];
    const [pagination, setPagination] = useRecoilState(contactPagination);
    limitUrl = limitUrl && limits.indexOf(limitUrl) != -1 ? parseInt(limitUrl) : null;
    page = page ? parseInt(page) : null;
    const [data, setData] = useState(tableData);
    const [changed, setChanged] = useState(false);
    const [filteredData, setFilteredData]: any = useState([]);
    const [showNavBtn, setShowNavBtn] = useState(false);
    const [count, setCount] = useState(0);
    const history = useHistory();
    const [directionNewPage, setDirection] = useState<any>(null);
    const offset = 0;
    const updateOffset: any = () => { };
    const [contact, setContact] = useState(0);

    // UseEffect used for reading url when loading page. So if a user clicks a link with ?page=3&limit=100 this will read it and save it
    useEffect(() => {
        if (pagination.init) {
            setPagination({
                pageLimit: limitUrl,
                page: page === 0 ? 1 : page,
                init: false,
            });
        }
    }, []);

    // UpdatePage for Pagination component outside this hook because also updates the URL on the browser
    const updatePage = (page: any) => {
        updateUrl(pagination.pageLimit, page);
        setPagination({
            pageLimit: pagination.pageLimit,
            page: page,
            init: pagination.init,
        });
    };

    // UpdatePage for local logic of the hook, does not update URL on the browser
    const updatePageLocal = (page: any) => {
        setPagination({
            pageLimit: pagination.pageLimit,
            page: page,
            init: pagination.init,
        });
    };

    // Update sort value
    const updateSort = (data: ISort) => {
        setSort(data);
    };

    // Update the limit for the petition to the backend for pagination component (used outside this hook). Also updates URL
    const updateQueryLimit = (limit: number) => {
        let newPage = 1;
        if (limit > pagination.pageLimit) {
            // new limit is bigger than current
            const count = pagination.pageLimit * pagination.page - 1;
            const newCount = count - (count % limit);
            newPage = newCount / limit + 1;
        } else {
            // new limit is smaller
            const newOffset = (pagination.page - 1) * pagination.pageLimit;
            newPage = newOffset / limit + 1;
        }
        setPagination({
            pageLimit: limit,
            page: newPage,
            init: pagination.init,
        });
        updateUrl(limit, newPage);
    };

    // Update count
    const updateCount = (count: number) => {
        setCount(count);
    };

    // Check toggle for a row
    const toggleCheck = async (id: string) => {
        const ids = id.split("%");
        const array = data.filter((e) => ids.includes(e.id));
        setFilteredData(array);
    };

    const selectAll = (e: any) => { };

    useEffect(() => {
        setFilteredData([]);
    }, [data]);

    // Update url on the browser with limit and page info
    const updateUrl = (limit: number, page: number) => {
        history.push({
            search:
                "?" +
                new URLSearchParams({
                    page: page ? page.toString() : "1",
                    limit: limit ? limit.toString() : "10",
                }).toString(),
            state,
        });
        setChanged(true);
    };

    // When the url changes without being changed by the user (normally because it goes back in browser history) we update the limit and the page
    // according to the URL. We know when we have to change it because of the url thanks to the [changed, setChanged] state.
    useEffect(() => {
        const check = window.location.href.includes("-");
        if (
            window.location.href.includes("activity") ||
            window.location.href.includes("supervision")
        )
            setData([]);
        if (
            !check &&
            !changed &&
            page &&
            limitUrl &&
            (pagination.page !== page || limitUrl !== pagination.pageLimit)
        ) {
            setPagination({
                pageLimit: limitUrl ? limitUrl : pagination.pageLimit,
                page: page ? page : pagination.page,
                init: pagination.init,
            });
            updateUrl(limitUrl ? limitUrl : pagination.pageLimit, page ? page : pagination.page);
        }
        setChanged(false);
    }, [window.location.href]);

    // When changing the page to a page different from leads and clients we update the page to 1 and also reset limit to 100.
    useEffect(() => {
        if (
            !window.location.pathname.includes("lead") &&
            !window.location.pathname.includes("client")
        ) {
            setPagination({
                pageLimit: 10,
                page: 1,
                init: false,
            });
        }
    }, [window.location.pathname]);

    // Update contact count to show next to
    const updateContact = (id: string) => {
        const check = window.location.href.includes("-");
        if (
            (window.location.pathname.includes("lead") ||
                window.location.pathname.includes("client")) &&
            check
        ) {
            const index = getIndex(id);
            const number =
                ((pagination.page || 1) - 1) * (pagination.pageLimit || 10) + (index || 0);
            setContact(number + 1);
        }
    };

    const getIndex = (id: string) => {
        var index = null;
        if (data) {
            for (const e in data) if (data[parseInt(e)]?.id === id) index = parseInt(e);
            return index;
        }
        return index;
    };

    // Move next function to use in single lead/client
    const moveNext = (id: string) => {
        var url = window.location.href.includes("lead")
            ? ROUTES.SINGLE_LEADS
            : ROUTES.SINGLE_CLIENTS;
        const result = getIndex(id);
        const tab = history.location.pathname.split("/").pop();
        result && console.log(data[result + 1]);
        if (data && (result || result === 0) && data[result + 1])
            history.push(buildUrl(url + "/" + tab, { id: data[result + 1].id }));
        else {
            updatePageLocal(pagination.page + 1);
            setDirection("next");
        }
    };

    // Move back function to use in single lead/client
    const moveBack = (id: string) => {
        var url = window.location.href.includes("lead")
            ? ROUTES.SINGLE_LEADS
            : ROUTES.SINGLE_CLIENTS;
        const result = getIndex(id);
        const tab = history.location.pathname.split("/").pop();
        if (data && result) {
            history.push(buildUrl(url + "/" + tab, { id: data[result - 1].id }));
        } else {
            if (pagination.page > 1) {
                updatePageLocal(pagination.page - 1);
                setDirection("prev");
            }
        }
    };

    // When data upadtes and we have a direction for the new page means we are moving in single client/lead and we had to change page
    useEffect(() => {
        if (data) {
            if (directionNewPage === "next") {
                moveNextNewPage();
            } else if (directionNewPage === "prev") {
                movePreviousNewPage();
            }
            setDirection(null);
        }
    }, [data]);

    const moveNextNewPage = () => {
        var url = window.location.href.includes("lead")
            ? ROUTES.SINGLE_LEADS
            : ROUTES.SINGLE_CLIENTS;
        const tab = history.location.pathname.split("/").pop();
        history.push(buildUrl(url + "/" + tab, { id: data[0].id }));
    };

    const movePreviousNewPage = () => {
        var url = window.location.href.includes("lead")
            ? ROUTES.SINGLE_LEADS
            : ROUTES.SINGLE_CLIENTS;
        const tab = history.location.pathname.split("/").pop();
        history.push(buildUrl(url + "/" + tab, { id: data[pagination.pageLimit - 1].id }));
    };

    const handleCustomLimit = (id: string) => {
        // const result = getIndex(id);
        // 
        // var off = (offset + 1) % 10;
        // if (result === 0 && off === 1 && offset > 0) {
        //     // when index is 0 it means we are in the first client/lead of the page, so what
        //     updateOffset(offset - pagination.pageLimit);
        //     updateCustomLimit(pagination.pageLimit + 2);
        // } else if ((result || -1) >= pagination.pageLimit) {
        //     updateOffset(offset + pagination.pageLimit);
        //     updateCustomLimit(null);
        // }
    };

    return {
        page: pagination.page || 1,
        updatePage,
        data,
        setData,
        toggleCheck,
        selectAll,
        checkedAll,
        count,
        updateCount,
        filteredData,
        sort,
        updateSort,
        limitQuery: pagination.pageLimit || 10,
        updateQueryLimit,
        handleCustomLimit,
        moveNext,
        offset,
        updateOffset,
        moveBack,
        showNavBtn,
        setShowNavBtn,
        contact,
        updateContact,
    };
}

export default useCheckAll;
