import { DB_ENUMS } from "../../config";

export const ALL_PROGRAMS = [
    "Address Removal",
    "Bankruptcy",
    "Business VIP",
    "Club Fico Guaranteed",
    "Credit Report",
    "Credito y Casas",
    "Funding",
    "Homestead",
    "ID Theft",
    "ID Theft Alert",
    "Inquiries",
    "Judgement",
    "Living Trust",
    "Mailing Address",
    "New Credit Card",
    "Public Records",
    "Rapid Rescore",
    "Regular",
    "Remove Disputes",
    "RFV",
    "Score Simulator",
    "Single Negotiation",
    "Tradelines",
    "Tradelines Funding",
    "VIP Credit",
];

export const CLUBS = [
    "CLUB 620",
    "CLUB 620 CV",
    "CLUB 640",
    "CLUB 660",
    "CLUB 680",
    "CLUB 700",
    "CLUB 720",
    "CLUB 740",
    "CLUB 760",
    "CLUB 780",
    "CLUB 800",
];

export const ALL_FUNDINGS = [
    "Funding - Business",
    "Funding - Personal",
    "Funding - Equity"
]

export const ALL_CLUBS = [
    "CLUB 620",
    "CLUB 620 CV",
    "CLUB 640",
    "CLUB 660",
    "CLUB 680",
    "CLUB 700",
    "CLUB 720",
    "CLUB 740",
    "CLUB 760",
    "CLUB 780",
    "CLUB 800",
    "CLUB 620 2nd Contract",
    "CLUB 620 CV 2nd Contract",
    "CLUB 640 2nd Contract",
    "CLUB 660 2nd Contract",
    "CLUB 680 2nd Contract",
    "CLUB 700 2nd Contract",
    "CLUB 720 2nd Contract",
    "CLUB 740 2nd Contract",
    "CLUB 760 2nd Contract",
    "CLUB 780 2nd Contract",
    "CLUB 800 2nd Contract",
    "CLUB 620 VIP",
    "CLUB 640 VIP",
    "CLUB 660 VIP",
    "CLUB 680 VIP",
];

export const PROGRAM_DROPDOWN_OPTIONS = [
    ...ALL_PROGRAMS.filter((p) => p !== "Club Fico Guaranteed" && p !== "Funding"),
    ...ALL_CLUBS,
    ...ALL_FUNDINGS,
].sort();

export const CLUBS_TYPES = ["Regular", "2nd Contract", "VIP"];

export const PROGRAM_STATUS = ["ACTIVE", "HOLD", "CANCELED", "COMPLETED"];

export const TRADELINE_STATUS = ["Needs Tradeline", "Tradeline Ordered", "Tradeline Completed"];

export const FUNDING_TYPES = ["Business", "Personal", "Equity"];

const PRICE_COST_FIELDS = (
    width1: string,
    width2: string,
    validate: boolean,
    priceAutomated?: (options: any) => number | null
) => [
        {
            title: "Cost",
            accesKey: "cost",
            required: true,
            type: "Money",
            width: width1 === "" ? "lg:col-span-4 sm:col-span-2 col-span-8" : width1,
            editable: false,
            finalValidation: validate,
            onlyFinal: validate,
            includeInType: ["Regular", "2nd Contract", "VIP"],
        },
        {
            title: "Price",
            accesKey: "price",
            required: true,
            type: "Money",
            width: width2 === "" ? "lg:col-span-4 sm:col-span-2 col-span-8" : width2,
            editable: priceAutomated === undefined,
            finalValidation: validate,
            finalValidationEditable: true,
            onlyFinal: validate,
            includeInType: ["Regular", "2nd Contract", "VIP"],
            automated: priceAutomated,
        },
    ];

const SPECIAL_PRICING_FIELD = (
    width: string = "lg:col-span-4 sm:col-span-2 col-span-8",
    title: string = "Special Pricing"
) => ({
    title,
    accesKey: "special_pricing",
    type: "Checkbox",
    width,
    editable: true,
    validate: true,
    finalValidationEditable: false,
    required: false,
    includeInType: ["Regular", "2nd Contract", "VIP"],
});

interface PaymentFieldsOptions {
    estimated_deposit?: {
        editable?: boolean;
        automated?: (options: any) => number;
    };
    estimated_monthly?: {
        hidden?: boolean;
    };
    fieldValidate?: {
        validate: (value: any) => boolean;
        message: string;
    };
}

const ESTIMATED_DEPOSIT_FIELD = (
    final: boolean = false,
    width: string = "lg:col-span-4 sm:col-span-2 col-span-8",
    options: PaymentFieldsOptions = {}
) => [
        {
            title: "Estimated Deposit",
            accesKey: "estimated_deposit",
            required: false,
            type: "Money",
            width,
            editable:
                options.estimated_deposit?.editable === undefined
                    ? true
                    : options.estimated_deposit.editable,
            validationEditable: true,
            finalValidation: final,
            finalValidationEditable: true,
            onlyFinal: final,
            includeInType: ["Regular", "2nd Contract", "VIP"],
            automated: options.estimated_deposit?.automated,
            fieldValidate: options.fieldValidate,
        },
    ];

const PAYMENT_FIELDS = (
    final: boolean = false,
    width: string = "lg:col-span-4 sm:col-span-2 col-span-8",
    options: PaymentFieldsOptions = {}
) => [
        {
            title: "Payment Option",
            accesKey: "payment_option",
            required: true,
            editable: true,
            type: "Dropdown",
            width,
            options: [...DB_ENUMS.PAYMENT_OPTIONS],
            isObject: true,
            objectKeyValue: "value",
            objectKeyDisplay: "label",
            finalValidation: final,
            finalValidationEditable: true,
            onlyFinal: final,
            includeInType: ["Regular", "2nd Contract", "VIP"],
        },
        ...ESTIMATED_DEPOSIT_FIELD(final, width, options),
        ...(!options.estimated_monthly?.hidden
            ? [
                {
                    title: "Estimated Monthly Payment",
                    accesKey: "estimated_monthly",
                    required: true,
                    type: "Money",
                    width,
                    editable: false,
                    finalValidation: final,
                    finalValidationEditable: true,
                    onlyFinal: final,
                    automated: ({ payment_option, price, estimated_deposit, isFinalValidation, isValidating }: any) => {
                        const option = payment_option || 0;
                        const total = price || 0;
                        const deposit = estimated_deposit || 0;
                        if (total !== 0 && option && option !== 0) {
                            return parseFloat(((total - deposit) / option).toFixed(2));
                        } else if (total !== 0 && option === 0) {
                            if (isFinalValidation || isValidating) return total;
                            return total * 0.9;
                        }
                        return 0;
                    },
                    includeInType: ["Regular", "2nd Contract", "VIP"],
                },
                {
                    title: "Pay Day",
                    accesKey: "pay_day",
                    required: true,
                    type: "Pay Day",
                    width,
                    editable: true,
                    fieldValidate: {
                        validate: (value: number) => {
                            if (!value) return true;
                            return value >= 1 && value <= 31;
                        },
                        message: "The value must be between 1 and 31.",
                    },
                    finalValidation: final,
                    finalValidationEditable: true,
                    onlyFinal: final,
                    includeInType: ["Regular", "2nd Contract", "VIP"],
                },
            ]
            : []),
    ];

const EXTRA_FIELDS_ACCOUNTS = () => [
    {
        title: "Num. Collections",
        accesKey: "num_collections",
        required: false,
        type: "Number",
        width: "col-span-4 sm:col-span-2",
        editable: true,
        validate: true,
        finalValidationEditable: true,
    },
    {
        title: "Num. Inquiries",
        accesKey: "num_inquiries",
        required: false,
        type: "Number",
        width: "col-span-4 sm:col-span-2",
        editable: true,
        validate: true,
        finalValidationEditable: true,
    },
    {
        title: "Num. Public Records",
        accesKey: "num_public_records",
        required: false,
        type: "Number",
        width: "col-span-4 sm:col-span-2",
        editable: true,
        validate: true,
        finalValidationEditable: true,
    },
    {
        title: "Num. Open Accounts",
        accesKey: "num_open_accounts",
        required: false,
        type: "Number",
        width: "col-span-4 sm:col-span-2",
        editable: true,
        validate: true,
        finalValidationEditable: true,
    },
]

export const CREDIT_REPORT = {
    popupWidth: "w-[700px]",
    fields: [
        {
            title: "Desired Program",
            accesKey: "name",
            required: true,
            type: "Dropdown",
            options: ALL_PROGRAMS,
            width: "lg:col-span-4 col-span-8",
            search: "Programs",
            editable: false,
        },
        {
            title: "Goal",
            accesKey: "goal",
            type: "DynamicDropdown",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            search: "Goals",
            required: true,
            editable: true,
        },
        {
            title: "Payment Type",
            accesKey: "payment_type",
            type: "DynamicDropdown",
            search: "Payment Type",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            required: true,
            editable: true,
        },
    ],
};

export const TRADELINES = {
    validate: true,
    popupWidth: "w-[700px]",
    fields: [
        {
            title: "Desired Program",
            accesKey: "name",
            required: true,
            type: "Dropdown",
            options: ALL_PROGRAMS,
            width: "sm:col-span-5 col-span-8",
            search: "Programs",
            editable: false,
            validate: true,
            finalValidationEditable: false,
        },
        {
            title: "Accounts",
            accesKey: "accounts",
            required: true,
            type: "Accounts",
            width: "sm:col-span-3 col-span-8",
            editable: true,
            validate: true,
            finalValidationEditable: false,
        },
        {
            title: "Number of tradelines",
            accesKey: "tradelines",
            required: true,
            type: "Number",
            width: "sm:col-span-4 col-span-8",
            editable: false,
            validate: true,
            automated: ({ accounts_positive, accounts_negative }: any) => {
                if (accounts_negative <= 8)
                    return 2 - accounts_positive > 0 ? 2 - accounts_positive : 0;
                else return 3 - accounts_positive > 0 ? 3 - accounts_positive : 0;
            },
            finalValidationEditable: false,
        },
        {
            title: "Payment Type",
            accesKey: "payment_type",
            type: "DynamicDropdown",
            search: "Payment Type",
            width: "sm:col-span-4 col-span-8",
            required: true,
            editable: true,
            finalValidationEditable: false,
        },
        {
            title: "Goal",
            accesKey: "goal",
            type: "DynamicDropdown",
            width: "lg:col-span-8 col-span-8",
            search: "Goals",
            required: true,
            editable: true,
            finalValidationEditable: false,
        },
        ...EXTRA_FIELDS_ACCOUNTS(),
        ...PRICE_COST_FIELDS("", "", true),
        ...PAYMENT_FIELDS(true),
        { ...SPECIAL_PRICING_FIELD() },
        {
            title: "Select the Credit Report from the Documents List",
            accesKey: "credit_report",
            type: "CreditReport",
            width: "lg:col-span-8",
            required: true,
            editable: true,
            validate: true,
            finalValidationEditable: false,
        },
    ],
};

export const SINGLE_NEGOTIATION = {
    validate: true,
    popupWidth: "w-[800px]",
    fields: [
        {
            title: "Desired Program",
            accesKey: "name",
            required: true,
            type: "Dropdown",
            options: ALL_PROGRAMS,
            width: "sm:col-span-2 col-span-4",
            search: "Programs",
            editable: false,
            validate: true,
        },
        {
            title: "Accounts",
            accesKey: "accounts",
            required: true,
            type: "Accounts",
            width: "sm:col-span-2 col-span-4",
            editable: true,
            validate: true,
            finalValidationEditable: false,
        },
        {
            title: "Payment Type",
            accesKey: "payment_type",
            type: "DynamicDropdown",
            search: "Payment Type",
            width: "sm:col-span-2 col-span-4",
            required: true,
            editable: true,
        },
        {
            title: "Goal",
            accesKey: "goal",
            type: "DynamicDropdown",
            width: "sm:col-span-2 col-span-4",
            search: "Goals",
            required: true,
            editable: true,
        },
        {
            title: "Collections",
            accesKey: "collections",
            required: true,
            type: "Collections",
            options_amount: ["$1 to $1000", "$1001 to $2000", "$2001 to $3000", "$3001 to $..."],
            width: "lg:col-span-4 col-span-8",
            editable: true,
            validate: true,
            finalValidationEditable: false,
        },
        ...EXTRA_FIELDS_ACCOUNTS(),
        ...PRICE_COST_FIELDS("", "", true),
        ...PAYMENT_FIELDS(true),
        { ...SPECIAL_PRICING_FIELD() },
        {
            title: "Select the Credit Report from the Documents List",
            accesKey: "credit_report",
            type: "CreditReport",
            width: "col-span-8",
            required: true,
            validate: true,
            finalValidationEditable: false,
        },
    ],
};

export const ADDRESS_REMOVAL = {
    popupWidth: "w-[700px]",
    fields: [
        {
            title: "Desired Program",
            accesKey: "name",
            required: true,
            type: "Dropdown",
            options: ALL_PROGRAMS,
            width: "sm:col-span-2 col-span-8",
            search: "Programs",
            editable: false,
        },
        {
            title: "Goal",
            accesKey: "goal",
            type: "DynamicDropdown",
            width: "lg:col-span-3 sm:col-span-4 col-span-8",
            search: "Goals",
            required: true,
            editable: true,
        },
        {
            title: "Payment Type",
            accesKey: "payment_type",
            type: "DynamicDropdown",
            search: "Payment Type",
            width: "lg:col-span-3 sm:col-span-4 col-span-8",
            required: true,
            editable: true,
        },
        ...PRICE_COST_FIELDS("", "", false),
        ...PAYMENT_FIELDS(),
        { ...SPECIAL_PRICING_FIELD() },
    ],
};

export const RFV = {
    popupWidth: "w-[700px]",
    fields: [
        {
            title: "Desired Program",
            accesKey: "name",
            required: true,
            type: "Dropdown",
            options: ALL_PROGRAMS,
            width: "sm:col-span-4 col-span-8",
            search: "Programs",
            editable: false,
        },
        {
            title: "Goal",
            accesKey: "goal",
            type: "DynamicDropdown",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            search: "Goals",
            required: true,
            editable: true,
        },
        {
            title: "Payment Type",
            accesKey: "payment_type",
            type: "DynamicDropdown",
            search: "Payment Type",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            required: true,
            editable: true,
        },
        ...PRICE_COST_FIELDS("", "", false),
        ...PAYMENT_FIELDS(),
        { ...SPECIAL_PRICING_FIELD() },
    ],
};

export const JUDGEMENT = {
    popupWidth: "w-[700px]",
    fields: [
        {
            title: "Desired Program",
            accesKey: "name",
            required: true,
            type: "Dropdown",
            options: ALL_PROGRAMS,
            width: "lg:col-span-4 sm:col-span-2 col-span-8",
            search: "Programs",
            editable: false,
        },
        {
            title: "Goal",
            accesKey: "goal",
            type: "DynamicDropdown",
            width: "lg:col-span-2 sm:col-span-3 col-span-8",
            search: "Goals",
            required: true,
            editable: true,
        },
        {
            title: "Payment Type",
            accesKey: "payment_type",
            type: "DynamicDropdown",
            search: "Payment Type",
            width: "lg:col-span-2 sm:col-span-3 col-span-8",
            required: true,
            editable: true,
        },
        ...PRICE_COST_FIELDS("sm:col-span-4 col-span-8", "sm:col-span-4 col-span-8", false),
        ...PAYMENT_FIELDS(),
        { ...SPECIAL_PRICING_FIELD() },
    ],
};

export const BANKRUPTCY = {
    popupWidth: "w-[700px]",
    fields: [
        {
            title: "Desired Program",
            accesKey: "name",
            required: true,
            type: "Dropdown",
            options: ALL_PROGRAMS,
            width: "sm:col-span-2 col-span-8",
            search: "Programs",
            editable: false,
        },
        {
            title: "BK Type",
            accesKey: "bk_type",
            required: true,
            type: "Dropdown",
            options: ["SINGLE BK", "JOINT BK"],
            width: "sm:col-span-2 col-span-8",
            editable: true,
        },
        {
            title: "Goal",
            accesKey: "goal",
            type: "DynamicDropdown",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            search: "Goals",
            required: true,
            editable: true,
        },
        {
            title: "Payment Type",
            accesKey: "payment_type",
            type: "DynamicDropdown",
            search: "Payment Type",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            required: true,
            editable: true,
        },
        ...PRICE_COST_FIELDS("", "", false),
        ...PAYMENT_FIELDS(),
        { ...SPECIAL_PRICING_FIELD() },
    ],
};

export const CLUB_FICO_GUARANTEED = {
    popupWidth: "w-[900px]",
    validate: true,
    fields: [
        {
            title: "Desired Program",
            accesKey: "name",
            required: true,
            type: "Dropdown",
            options: ALL_PROGRAMS,
            width: "lg:col-span-5 sm:col-span-4 col-span-8",
            search: "Programs",
            editable: false,
            validate: true,
            finalValidationEditable: false,
        },
        {
            title: "Club Type",
            accesKey: "club_type",
            required: true,
            type: "Dropdown",
            options: [...CLUBS_TYPES],
            width: "lg:col-span-3 sm:col-span-4 col-span-8",
            validate: true,
            editable: true,
            finalValidationEditable: false,
        },
        {
            title: "Desired Club",
            accesKey: "club",
            required: true,
            type: "Dropdown",
            options: [...CLUBS],
            width: "lg:col-span-3 sm:col-span-4 col-span-8",
            search: "Programs",
            validate: true,
            editable: true,
            finalValidationEditable: false,
            includeInType: ["Regular", "2nd Contract"],
        },
        {
            title: "Desired Club",
            accesKey: "club",
            required: true,
            type: "Dropdown",
            options: ["CLUB 620", "CLUB 640", "CLUB 660", "CLUB 680"],
            width: "lg:col-span-3 sm:col-span-4 col-span-8",
            search: "Programs",
            validate: true,
            editable: true,
            finalValidationEditable: false,
            includeInType: ["VIP"],
        },
        {
            title: "Accounts",
            accesKey: "accounts",
            required: true,
            type: "Accounts",
            width: "lg:col-span-3 sm:col-span-4 col-span-8",
            validate: true,
            editable: true,
            finalValidationEditable: false,
            includeInType: ["Regular", "2nd Contract", "VIP"],
        },
        {
            title: "Tradelines",
            accesKey: "tradelines",
            required: true,
            type: "Dropdown",
            options: ["0", "1", "2", "3"],
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            validate: true,
            editable: false,
            finalValidationEditable: false,
            automated: ({ accounts_positive, accounts_negative }: any) => {
                if (accounts_negative <= 8)
                    return 2 - accounts_positive > 0 ? 2 - accounts_positive : 0;
                else return 3 - accounts_positive > 0 ? 3 - accounts_positive : 0;
            },
            includeInType: ["Regular"],
        },
        ...EXTRA_FIELDS_ACCOUNTS(),
        {
            title: "State",
            accesKey: "state",
            required: true,
            type: "Dropdown",
            options: [
                "AK",
                "AL",
                "AR",
                "AZ",
                "CA",
                "CO",
                "CT",
                "DE",
                "FL",
                "GA",
                "HI",
                "IA",
                "ID",
                "IL",
                "IN",
                "KS",
                "KY",
                "LA",
                "MA",
                "MD",
                "ME",
                "MI",
                "MN",
                "MO",
                "MS",
                "MT",
                "NC",
                "ND",
                "NE",
                "NH",
                "NJ",
                "NM",
                "NV",
                "NY",
                "OH",
                "OK",
                "OR",
                "PA",
                "RI",
                "SC",
                "SD",
                "TN",
                "TX",
                "UT",
                "VA",
                "VT",
                "WA",
                "WI",
                "WV",
                "WY",
                "AS",
                "AA",
                "AE",
                "AP",
                "PW",
                "DC",
                "VI",
                "PR",
                "FM",
                "MP",
                "GU",
            ],
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            editable: true,
            finalValidationEditable: false,
            includeInType: ["Regular"],
        },
        {
            title: "Payment Type",
            accesKey: "payment_type",
            type: "DynamicDropdown",
            search: "Payment Type",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            required: true,
            editable: true,
            finalValidationEditable: false,
            includeInType: ["Regular", "2nd Contract", "VIP"],
        },
        {
            title: "Goal",
            accesKey: "goal",
            type: "DynamicDropdown",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            search: "Goals",
            required: true,
            editable: true,
            finalValidationEditable: false,
            includeInType: ["Regular", "2nd Contract", "VIP"],
        },
        ...PRICE_COST_FIELDS(
            "lg:col-span-2 sm:col-span-4 col-span-8",
            "lg:col-span-2 sm:col-span-4 col-span-8",
            true
        ),
        ...PAYMENT_FIELDS(true, "lg:col-span-2 sm:col-span-4 col-span-8"),
        { ...SPECIAL_PRICING_FIELD("lg:col-span-2 sm:col-span-2 col-span-8") },
        {
            title: "Select the Credit Report from the Documents List",
            accesKey: "credit_report",
            type: "CreditReport",
            width: "col-span-8",
            validate: true,
            required: true,
            includeInType: ["Regular", "2nd Contract", "VIP"],
        },
    ],
};

export const MANUAL_PROGRAM = {
    popupWidth: "w-[700px]",
    fields: [
        {
            title: "Desired Program",
            accesKey: "name",
            required: true,
            type: "Dropdown",
            options: ALL_PROGRAMS,
            width: "sm:col-span-4 col-span-8",
            search: "Programs",
            editable: false,
        },
        {
            title: "Goal",
            accesKey: "goal",
            type: "DynamicDropdown",
            width: "sm:col-span-4 col-span-8",
            search: "Goals",
            required: true,
            editable: true,
        },
    ],
};

export const INQUIRIES = {
    popupWidth: "w-[800px]",
    fields: [
        {
            title: "Desired Program",
            accesKey: "name",
            required: true,
            type: "Dropdown",
            options: ALL_PROGRAMS,
            width: "sm:col-span-2 col-span-8",
            search: "Programs",
            editable: false,
        },
        {
            title: "Number of Inquiries",
            accesKey: "inquiries",
            required: true,
            type: "Number",
            typeInput: "number",
            width: "sm:col-span-2 col-span-8",
            editable: true,
        },
        {
            title: "Goal",
            accesKey: "goal",
            type: "DynamicDropdown",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            search: "Goals",
            required: true,
            editable: true,
        },
        {
            title: "Payment Type",
            accesKey: "payment_type",
            type: "DynamicDropdown",
            search: "Payment Type",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            required: true,
            editable: true,
        },
        ...PRICE_COST_FIELDS("", "", false),
        ...PAYMENT_FIELDS(),
        { ...SPECIAL_PRICING_FIELD() },
    ],
};

export const RAPID_RESCORE = {
    popupWidth: "w-[900px]",
    fields: [
        {
            title: "Desired Program",
            accesKey: "name",
            required: true,
            type: "Dropdown",
            options: ALL_PROGRAMS,
            width: "md:col-span-2 col-span-8",
            search: "Programs",
            editable: false,
        },
        {
            title: "Bureaus",
            accesKey: "bureaus",
            required: true,
            type: "Bureaus",
            width: "md:col-span-2 col-span-8",
            editable: true,
        },
        {
            title: "Goal",
            accesKey: "goal",
            type: "DynamicDropdown",
            width: "lg:col-span-2 md:col-span-4 col-span-8",
            search: "Goals",
            required: true,
            editable: true,
        },
        {
            title: "Payment Type",
            accesKey: "payment_type",
            type: "DynamicDropdown",
            search: "Payment Type",
            width: "lg:col-span-2 md:col-span-4 col-span-8",
            required: true,
            editable: true,
        },
        ...PRICE_COST_FIELDS("", "", false),
        ...PAYMENT_FIELDS(),
        { ...SPECIAL_PRICING_FIELD() },
    ],
};

export const ID_THEFT_ALERTS = {
    popupWidth: "w-[900px]",
    fields: [
        {
            title: "Desired Program",
            accesKey: "name",
            required: true,
            type: "Dropdown",
            options: ALL_PROGRAMS,
            width: "md:col-span-2 col-span-8",
            search: "Programs",
            editable: false,
        },
        {
            title: "Bureaus",
            accesKey: "bureaus",
            required: true,
            type: "Bureaus",
            width: "md:col-span-2 col-span-8",
            editable: true,
        },
        {
            title: "Goal",
            accesKey: "goal",
            type: "DynamicDropdown",
            width: "lg:col-span-2 md:col-span-4 col-span-8",
            search: "Goals",
            required: true,
            editable: true,
        },
        {
            title: "Payment Type",
            accesKey: "payment_type",
            type: "DynamicDropdown",
            search: "Payment Type",
            width: "lg:col-span-2 md:col-span-4 col-span-8",
            required: true,
            editable: true,
        },
        ...PRICE_COST_FIELDS(
            "lg:col-span-4 md:col-span-2 col-span-8",
            "lg:col-span-4 md:col-span-2 col-span-8",
            false
        ),
        ...PAYMENT_FIELDS(),
        { ...SPECIAL_PRICING_FIELD() },
    ],
};

export const ID_THEFT = {
    popupWidth: "w-[700px]",
    validateWidth: "w-[700px]",
    validate: true,
    fields: [
        {
            title: "Desired Program",
            accesKey: "name",
            required: true,
            type: "Dropdown",
            options: ALL_PROGRAMS,
            width: "lg:col-span-4 sm:col-span-4 col-span-8",
            search: "Programs",
            editable: false,
            validate: true,
            validateWidth: "lg:col-span-4",
        },
        {
            title: "Accounts",
            accesKey: "accounts",
            required: true,
            type: "Accounts",
            width: "lg:col-span-4 sm:col-span-4 col-span-8 mt-[0.2rem]",
            editable: true,
            validate: true,
            finalValidationEditable: false,
            validateWidth: "lg:col-span-4",
        },
        {
            title: "Payment Type",
            accesKey: "payment_type",
            type: "DynamicDropdown",
            search: "Payment Type",
            width: "lg:col-span-4 sm:col-span-4 col-span-8",
            required: true,
            editable: true,
        },
        {
            title: "Goal",
            accesKey: "goal",
            type: "DynamicDropdown",
            width: "lg:col-span-4 sm:col-span-4 col-span-8",
            search: "Goals",
            required: true,
            editable: true,
        },
        ...EXTRA_FIELDS_ACCOUNTS(),
        ...PRICE_COST_FIELDS("", "", true),
        ...PAYMENT_FIELDS(true),
        { ...SPECIAL_PRICING_FIELD() },
        {
            title: "Select the Credit Report from the Documents List",
            accesKey: "credit_report",
            type: "CreditReport",
            width: "lg:col-span-8",
            validate: true,
            required: true,
        },
    ],
};

export const PUBLIC_RECORDS = {
    popupWidth: "w-[700px]",
    fields: [
        {
            title: "Desired Program",
            accesKey: "name",
            required: true,
            type: "Dropdown",
            options: ALL_PROGRAMS,
            width: "sm:col-span-2 col-span-8",
            search: "Programs",
            editable: false,
        },
        {
            title: "Number of Records",
            accesKey: "records",
            required: true,
            type: "Dropdown",
            options: ["1", "2", "3"],
            width: "sm:col-span-2 col-span-8",
            editable: true,
        },
        {
            title: "Goal",
            accesKey: "goal",
            type: "DynamicDropdown",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            search: "Goals",
            required: true,
            editable: true,
        },
        {
            title: "Payment Type",
            accesKey: "payment_type",
            type: "DynamicDropdown",
            search: "Payment Type",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            required: true,
            editable: true,
        },
        ...PRICE_COST_FIELDS("", "", false),
        ...PAYMENT_FIELDS(),
        { ...SPECIAL_PRICING_FIELD() },
    ],
};

export const MAILING_ADDRESS = {
    popupWidth: "w-[700px]",
    fields: [
        {
            title: "Desired Program",
            accesKey: "name",
            required: true,
            type: "Dropdown",
            options: ALL_PROGRAMS,
            width: "lg:col-span-3 sm:col-span-4 col-span-8",
            search: "Programs",
            editable: false,
        },
        {
            title: "Goal",
            accesKey: "goal",
            type: "DynamicDropdown",
            width: "lg:col-span-3 sm:col-span-4 col-span-8",
            search: "Goals",
            required: true,
            editable: true,
        },
        {
            title: "Payment Type",
            accesKey: "payment_type",
            type: "DynamicDropdown",
            search: "Payment Type",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            required: true,
            editable: true,
        },
        ...PRICE_COST_FIELDS("", "", false),
        ...PAYMENT_FIELDS(),
        { ...SPECIAL_PRICING_FIELD() },
    ],
};

export const SCORE_SIMULATOR = {
    popupWidth: "w-[700px]",
    fields: [
        {
            title: "Desired Program",
            accesKey: "name",
            required: true,
            type: "Dropdown",
            options: ALL_PROGRAMS,
            width: "sm:col-span-2 col-span-8",
            search: "Programs",
            editable: false,
        },
        {
            title: "Number of Simulations",
            accesKey: "simulations",
            required: true,
            type: "Dropdown",
            options: ["1", "2", "3"],
            width: "sm:col-span-2 col-span-8",
            editable: true,
        },
        {
            title: "Goal",
            accesKey: "goal",
            type: "DynamicDropdown",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            search: "Goals",
            required: true,
            editable: true,
        },
        {
            title: "Payment Type",
            accesKey: "payment_type",
            type: "DynamicDropdown",
            search: "Payment Type",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            required: true,
            editable: true,
        },
        ...PRICE_COST_FIELDS("", "", false),
        ...PAYMENT_FIELDS(),
        { ...SPECIAL_PRICING_FIELD() },
    ],
};

export const REGULAR = {
    popupWidth: "w-[700px]",
    fields: [
        {
            title: "Desired Program",
            accesKey: "name",
            required: true,
            type: "Dropdown",
            options: ALL_PROGRAMS,
            width: "sm:col-span-4 col-span-8",
            search: "Programs",
            editable: false,
        },
        {
            title: "Goal",
            accesKey: "goal",
            type: "DynamicDropdown",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            search: "Goals",
            required: true,
            editable: true,
        },
        {
            title: "Payment Type",
            accesKey: "payment_type",
            type: "DynamicDropdown",
            search: "Payment Type",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            required: true,
            editable: true,
        },
        ...PRICE_COST_FIELDS("", "", false),
        ...PAYMENT_FIELDS(),
        { ...SPECIAL_PRICING_FIELD() },
    ],
};

export const NEW_CREDIT_CARD = {
    popupWidth: "w-[700px]",
    fields: [
        {
            title: "Desired Program",
            accesKey: "name",
            required: true,
            type: "Dropdown",
            options: ALL_PROGRAMS,
            width: "sm:col-span-4 col-span-8",
            search: "Programs",
            editable: false,
        },
        {
            title: "Goal",
            accesKey: "goal",
            type: "DynamicDropdown",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            search: "Goals",
            required: true,
            editable: true,
        },
        {
            title: "Payment Type",
            accesKey: "payment_type",
            type: "DynamicDropdown",
            search: "Payment Type",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            required: true,
            editable: true,
        },
        {
            title: "Amount Approved",
            accesKey: "amount_approved",
            required: true,
            type: "Money",
            width: "lg:col-span-8 sm:col-span-4 col-span-8",
            editable: true,
            finalValidation: false,
            onlyFinal: false,
        },
        ...PRICE_COST_FIELDS("", "", false, ({ amount_approved }: any) => {
            const price = parseFloat((0.1 * amount_approved).toFixed(2));
            return price > 450 ? price : 450;
        }),
        ...PAYMENT_FIELDS(),
        { ...SPECIAL_PRICING_FIELD() },
    ],
};

export const REMOVE_DISPUTES = {
    popupWidth: "w-[900px]",
    fields: [
        {
            title: "Desired Program",
            accesKey: "name",
            required: true,
            type: "Dropdown",
            options: ALL_PROGRAMS,
            width: "sm:col-span-4 col-span-8",
            search: "Programs",
            editable: false,
        },
        {
            title: "Goal",
            accesKey: "goal",
            type: "DynamicDropdown",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            search: "Goals",
            required: true,
            editable: true,
        },
        {
            title: "Payment Type",
            accesKey: "payment_type",
            type: "DynamicDropdown",
            search: "Payment Type",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            required: true,
            editable: true,
        },
        {
            title: "Account Numbers",
            accesKey: "account_numbers",
            type: "AccountNumbers",
            width: "lg:col-span-8 sm:col-span-8 col-span-8",
            required: true,
            editable: true,
        },
        ...PRICE_COST_FIELDS("", "", false, (options) => options.cost),
        ...PAYMENT_FIELDS(),
        { ...SPECIAL_PRICING_FIELD() },
    ],
};

export const HOMESTEAD = {
    popupWidth: "w-[700px]",
    fields: [
        {
            title: "Desired Program",
            accesKey: "name",
            required: true,
            type: "Dropdown",
            options: ALL_PROGRAMS,
            width: "sm:col-span-4 col-span-8",
            search: "Programs",
            editable: false,
        },
        {
            title: "Goal",
            accesKey: "goal",
            type: "DynamicDropdown",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            search: "Goals",
            required: true,
            editable: true,
        },
        {
            title: "Payment Type",
            accesKey: "payment_type",
            type: "DynamicDropdown",
            search: "Payment Type",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            required: true,
            editable: true,
        },
        {
            title: "Property Value",
            accesKey: "property_value",
            required: true,
            type: "Money",
            width: "lg:col-span-8 sm:col-span-4 col-span-8",
            editable: true,
            finalValidation: false,
            onlyFinal: false,
        },
        ...PRICE_COST_FIELDS("", "", false),
        ...PAYMENT_FIELDS(),
        { ...SPECIAL_PRICING_FIELD() },
    ],
};

export const LIVING_TRUST = {
    popupWidth: "w-[700px]",
    fields: [
        {
            title: "Desired Program",
            accesKey: "name",
            required: true,
            type: "Dropdown",
            options: ALL_PROGRAMS,
            width: "sm:col-span-4 col-span-8",
            search: "Programs",
            editable: false,
        },
        {
            title: "Goal",
            accesKey: "goal",
            type: "DynamicDropdown",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            search: "Goals",
            required: true,
            editable: true,
        },
        {
            title: "Payment Type",
            accesKey: "payment_type",
            type: "DynamicDropdown",
            search: "Payment Type",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            required: true,
            editable: true,
        },
        {
            title: "State",
            accesKey: "state",
            required: true,
            type: "Dropdown",
            options: [
                "AK",
                "AL",
                "AR",
                "AZ",
                "CA",
                "CO",
                "CT",
                "DE",
                "FL",
                "GA",
                "HI",
                "IA",
                "ID",
                "IL",
                "IN",
                "KS",
                "KY",
                "LA",
                "MA",
                "MD",
                "ME",
                "MI",
                "MN",
                "MO",
                "MS",
                "MT",
                "NC",
                "ND",
                "NE",
                "NH",
                "NJ",
                "NM",
                "NV",
                "NY",
                "OH",
                "OK",
                "OR",
                "PA",
                "RI",
                "SC",
                "SD",
                "TN",
                "TX",
                "UT",
                "VA",
                "VT",
                "WA",
                "WI",
                "WV",
                "WY",
                "AS",
                "AA",
                "AE",
                "AP",
                "PW",
                "DC",
                "VI",
                "PR",
                "FM",
                "MP",
                "GU",
            ],
            width: "lg:col-span-4 sm:col-span-4 col-span-8",
            editable: true,
            finalValidationEditable: false,
            validation: { validate: (value: any) => value === "CA" },
            validationMessage: "* Living Trust is only available in California.",
        },
        ...PRICE_COST_FIELDS("", "", false),
        ...PAYMENT_FIELDS(),
        { ...SPECIAL_PRICING_FIELD() },
    ],
};

export const VIP_CREDIT = {
    popupWidth: "w-[700px]",
    fields: [
        {
            title: "Desired Program",
            accesKey: "name",
            required: true,
            type: "Dropdown",
            options: ALL_PROGRAMS,
            width: "sm:col-span-4 col-span-8",
            search: "Programs",
            editable: false,
        },
        {
            title: "Accounts",
            accesKey: "accounts",
            required: true,
            type: "Accounts",
            width: "sm:col-span-4 col-span-8",
            editable: true,
            validate: true,
            finalValidationEditable: false,
        },
        {
            title: "Goal",
            accesKey: "goal",
            type: "DynamicDropdown",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            search: "Goals",
            required: true,
            editable: true,
        },
        {
            title: "Payment Type",
            accesKey: "payment_type",
            type: "DynamicDropdown",
            search: "Payment Type",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            required: true,
            editable: true,
        },
        ...EXTRA_FIELDS_ACCOUNTS(),
        ...PRICE_COST_FIELDS("", "", false),
        ...PAYMENT_FIELDS(),
        { ...SPECIAL_PRICING_FIELD() },
    ],
};

export const BUSINESS_VIP = {
    popupWidth: "w-[700px]",
    fields: [
        {
            title: "Desired Program",
            accesKey: "name",
            required: true,
            type: "Dropdown",
            options: ALL_PROGRAMS,
            width: "sm:col-span-4 col-span-8",
            search: "Programs",
            editable: false,
        },
        {
            title: "Goal",
            accesKey: "goal",
            type: "DynamicDropdown",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            search: "Goals",
            required: true,
            editable: true,
        },
        {
            title: "Payment Type",
            accesKey: "payment_type",
            type: "DynamicDropdown",
            search: "Payment Type",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            required: true,
            editable: true,
        },
        ...PRICE_COST_FIELDS("", "", false),
        ...PAYMENT_FIELDS(),
        { ...SPECIAL_PRICING_FIELD() },
    ],
};

export const FUNDING = {
    popupWidth: "w-[800px]",
    fields: [
        {
            title: "Desired Program",
            accesKey: "name",
            required: true,
            type: "Dropdown",
            options: ALL_PROGRAMS,
            width: "sm:col-span-4 col-span-8",
            search: "Programs",
            editable: false,
        },
        {
            title: "Goal",
            accesKey: "goal",
            type: "DynamicDropdown",
            width: "sm:col-span-4 col-span-8",
            search: "Goals",
            required: true,
            editable: true,
        },
        {
            title: "Payment Type",
            accesKey: "payment_type",
            type: "DynamicDropdown",
            search: "Payment Type",
            width: "sm:col-span-4 col-span-8",
            required: true,
            editable: true,
        },
        {
            title: "Agent Commission",
            accesKey: "agent_commission",
            required: true,
            type: "Money",
            width: "sm:col-span-4 col-span-8",
            editable: false,
            finalValidation: false,
            onlyFinal: false,
            automated: () => 300,
        },
        {
            title: "% Commission",
            accesKey: "commission_percent",
            required: true,
            type: "Number",
            typeInput: "number",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            editable: true,
            fieldValidate: {
                validate: (value: number) => {
                    return value >= 5;
                },
                message: "The commission must be at least 5%.",
            },
        },
        {
            title: "Tradelines",
            accesKey: "tradelines",
            required: true,
            type: "Number",
            typeInput: "number",
            width: "lg:col-span-2 sm:col-span-4 col-span-8",
            editable: true,
        },
        // ...PRICE_COST_FIELDS("", "", false, () => null),
        ...ESTIMATED_DEPOSIT_FIELD(undefined, undefined, {
            estimated_deposit: {
                automated: (options) => {
                    return options.funding_type !== 'Equity' ? 2500 : 0
                },
                editable: false
            },
        }),
        {
            title: "Funding Type",
            accesKey: "funding_type",
            required: true,
            type: "Dropdown",
            options: FUNDING_TYPES,
            width: "sm:col-span-4 col-span-8",
            editable: true,
        },
        { ...SPECIAL_PRICING_FIELD(undefined, "Special Conditions") },
        {
            title: "Select the Credit Report from the Documents List",
            accesKey: "credit_report",
            type: "CreditReport",
            width: "lg:col-span-8",
            required: true,
            editable: true,
            validate: true,
            finalValidationEditable: false,
        },
    ],
};

export const TRADELINES_FUNDING = {
    validate: true,
    popupWidth: "w-[700px]",
    fields: [
        {
            title: "Desired Program",
            accesKey: "name",
            required: true,
            type: "Dropdown",
            options: ALL_PROGRAMS,
            width: "sm:col-span-5 col-span-8",
            search: "Programs",
            editable: false,
            validate: true,
            finalValidationEditable: false,
        },
        {
            title: "Accounts",
            accesKey: "accounts",
            required: true,
            type: "Accounts",
            width: "sm:col-span-3 col-span-8",
            editable: true,
            validate: true,
            finalValidationEditable: false,
        },
        {
            title: "Number of tradelines",
            accesKey: "tradelines",
            required: true,
            type: "Number",
            width: "sm:col-span-4 col-span-8",
            editable: false,
            validate: true,
            automated: ({ accounts_positive, accounts_negative }: any) => {
                if (accounts_negative <= 8)
                    return 2 - accounts_positive > 0 ? 2 - accounts_positive : 0;
                else return 3 - accounts_positive > 0 ? 3 - accounts_positive : 0;
            },
            finalValidationEditable: false,
        },
        {
            title: "Payment Type",
            accesKey: "payment_type",
            type: "DynamicDropdown",
            search: "Payment Type",
            width: "sm:col-span-4 col-span-8",
            required: true,
            editable: true,
            finalValidationEditable: false,
        },
        {
            title: "Goal",
            accesKey: "goal",
            type: "DynamicDropdown",
            width: "sm:col-span-4 col-span-8",
            search: "Goals",
            required: true,
            editable: true,
            finalValidationEditable: false,
        },
        ...EXTRA_FIELDS_ACCOUNTS(),
        ...PRICE_COST_FIELDS("", "", true, ({ accounts_positive, accounts_negative }: any) => {
            let tradelines = 0;
            if (accounts_negative <= 8)
                tradelines = 2 - accounts_positive > 0 ? 2 - accounts_positive : 0;
            else tradelines = 3 - accounts_positive > 0 ? 3 - accounts_positive : 0;
            return 1000 * tradelines;
        }),
        ...PAYMENT_FIELDS(true, undefined, {
            fieldValidate: {
                validate: (value: number) => value >= 500,
                message: "The value must be greater or equal to 500.",
            },
        }),
        { ...SPECIAL_PRICING_FIELD() },
        {
            title: "Select the Credit Report from the Documents List",
            accesKey: "credit_report",
            type: "CreditReport",
            width: "lg:col-span-8",
            required: true,
            editable: true,
            validate: true,
            finalValidationEditable: false,
        },
    ],
};
