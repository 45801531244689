import { API, API_BASEURL, buildUrl, SWR_CONFIG } from "../../config";
import useGetSWR from "../../hooks/useGetSWR";
import { IsimplifiedUser } from "../../types";
import { CustomSelect } from "./genericDropdowns/CustomSelect";

type InputProps = {
    title?: string;
    placeholder?: string;
    name?: string;
    onChangeFunction?: (value: string, label: string) => void;
    className?: string;
    inputClassName?: string;
    titleClassname?: string;
    outerClass?: string;
    editing?: boolean;
    authO?: boolean;
    textBoxClass?: string;
    value?: string | number;
    children?: JSX.Element | false | undefined;
    authCheck?: boolean;
    calendar?: boolean;
    assignable?: boolean;
    task?: boolean;
    selectStyles?: any;
    payment?: boolean;
    disabledOptions?: string[]
};

export const DropDownUsers = ({
    title,
    placeholder = "",
    name = "",
    onChangeFunction,
    className,
    inputClassName,
    titleClassname,
    editing,
    textBoxClass,
    selectStyles,
    value = "",
    authO,
    children,
    authCheck,
    calendar = false,
    assignable = false,
    task = false,
    payment = false,
    outerClass,
    disabledOptions,
}: InputProps) => {
    const url = !calendar
        ? buildUrl(API.USER_SIMPLE_ALL_DROPDOWN, {}, API_BASEURL, {
              authCheck: authCheck,
              assignable: assignable,
              payment,
          })
        : buildUrl(API.USER_SIMPLE_CALENDAR, {}, API_BASEURL, { task });
    const { data: userListRes } = useGetSWR<IsimplifiedUser>(url, SWR_CONFIG.FORMS);
    const onChange = (val: any, action: any) => {
        if (!onChangeFunction) return "";

        if (action.action === "clear") onChangeFunction("", "");
        else onChangeFunction(val.value, val.label);
    };

    const toLabelValue = () => {
        const options = [];
        if (!userListRes) return [];
        for (const u of userListRes?.user) {
            const option = { value: authO ? (u as any).authO : u.id, label: u.name }
            if (!(disabledOptions && disabledOptions.includes(option.value))) {
                options.push(option);
            }
        }
        return options;
    };

    const getValue = () => {
        if (!userListRes) return "";
        const user = userListRes?.user.find((u) =>
            authO ? (u as any).authO === value : u.id === value
        );
        if (!user) return "";
        return { label: user.name, value: value };
    };

    return (
        <div className={`w-full ${outerClass && outerClass}`}>
            <CustomSelect
                title={title}
                placeholder={placeholder}
                name={name}
                onChangeFunction={onChange}
                className={className ? `flex flex-col w-full ${className}` : "flex flex-col mb-4"}
                titleClassName={titleClassname ? titleClassname : "text-r-medium-small-2"}
                editing={editing}
                value={getValue()}
                options={toLabelValue()}
                containerClassname=""
                isObject
                selectStyles={selectStyles}
            >
                {children}
            </CustomSelect>
        </div>
    );
};
