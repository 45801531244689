import { useState } from "react";
import { Link } from "react-router-dom";
import { CrmLogo, MenuIcon } from "../../../icons";
import { MenuItem } from "../../../views/MainScreen";
import { Autocomplete } from "../../autocomplete/Autocomplete";
import { Module } from "../navbar/Module";
import Notifications from "../navbar/Notifications";
import { User } from "../navbar/User";
import SidebarLink from "./SidebarLink";

export type SidebarProps = {
    menu: MenuItem[];
};

function Sidebar({ menu }: SidebarProps) {
    const [toggle, setToggle] = useState(false);

    return (
        <div className="overflow-visible">
            <div className="md:hidden flex justify-start bg-reyna-primary sticky top-0 z-50">
                <div className="w-full flex items-center">
                    <MenuIcon
                        onClick={() => setToggle(!toggle)}
                        className="text-white md:hidden  fill-current w-10 h-10 cursor-pointer m-2"
                    />
                    <Autocomplete />
                </div>
                <div className="ml-auto flex items-center w-3/5 justify-end gap-x-2">
                    <Module />
                    <Notifications />
                    <User />
                </div>
            </div>
            <div
                className={`flex flex-col bg-reyna-primary overflow-visible  overflow-y-auto scrollbar-hide md:sticky text-reyna-text-primary md:h-screen md:top-0
        font-inter font-normal text-r-large md:min-h-screen transition-all  ${
            toggle ? " sticky top-[var(--sidebar-height-mobile)] body-scroll-none h-screen" : ""
        } `}
            >
                <Link
                    to="/dashboard"
                    className={`md:flex overflow-visible flex transition-all items-center sidebar-link min-h-[55px]`}
                >
                    <div
                        className={`relative flex overflow-visible w-full h-full justify-center bg-reyna-primary-dark items-center`}
                    >
                        <CrmLogo className="fill-current transition-all" />
                    </div>
                </Link>
                <div
                    className={`transition-all overflow-visible flex flex-col ${
                        toggle ? "" : "h-0"
                    }`}
                >
                    {menu.map((item) => {
                        return (
                            <SidebarLink
                                toggleFunction={setToggle}
                                name={item.name}
                                url={item.url}
                                Icon={item.icon}
                                key={`sidebar-link-${item.name}`}
                                active={item.active}
                                tooltip={item.tooltip}
                                notifications={item.notification}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
