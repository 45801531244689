import { Link } from "react-router-dom";
import Button from "../buttons/Button";
export type FeedBoxProps = {
    title: string;
    buttonTitle?: string;
    buttonFunction?: () => void;
    children?: JSX.Element[];
    href?: string;
};
function FeedBox({ title, buttonTitle, buttonFunction, children, href }: FeedBoxProps) {
    return (
        <div className="flex flex-col w-full rounded-[10px] p-5 gap-6 bg-white lg:w-full overflow-hidden pb-10">
            <div className="flex justify-between sm:items-center flex-col sm:flex-row gap-2">
                <h1 className="text-r-large-big">{title}</h1>
                <div>
                    {href ? (
                        <a href={href} target="_blank" rel="noreferrer" className="bg-reyna-primary rounded-md flex items-center py-0.5">
                            <span
                                className="rounded-[5px] self-start whitespace-nowrap font-roboto font-medium text-r-medium text-reyna-text-primary px-3"
                            >
                                {buttonTitle}   
                            </span>
                        </a>
                    ) : (buttonTitle && children && children.length > 0 && (
                        <Button
                            title={buttonTitle}
                            clickFunction={buttonFunction}
                            className="rounded-[5px] self-start whitespace-nowrap font-roboto font-medium text-r-medium text-reyna-text-primary px-4"
                        />
                    ))
                }
                </div>
            </div>
            <div className="flex flex-col gap-4">
                {(children && children.length > 0 && (
                    <div>
                        {children}
                        <div className="flex justify-start items-center z-30 mr-10">
                            <div className="pt-[4px]  bg-white  w-9 flex justify-center">
                                <div className="bg-reyna-primary w-[16px] h-[16px] rounded-full z-0 mr-0.50 mt-4 " />
                            </div>
                        </div>
                    </div>
                )) || (
                    <div className="text-center my-[40px] font-inter italic text-r-medium-small text-reyna-text-2">
                        No results found
                    </div>
                )}
            </div>
        </div>
    );
}

export default FeedBox;
