import { toHtmlDateTableFormat, tsToHtmlDateFormat } from "../../utils";
var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

/**
 *  Date needs to be handle a little different as the db does not
 * accept an emoty string, when the date value is cleared it is set
 * to > '', so instead of an empty string we return null.
 */
export const formHandleDateType = (val: string | undefined, addTimezone: boolean) => ({
    value: tsToHtmlDateFormat(val),
    setValueAs: (v: any) => (v ? v : null),
});

/**
 *  Date needs to be handle a little different as the db does not
 * accept an emoty string, when the date value is cleared it is set
 * to > '', so instead of an empty string we return null.
 */
export const formDateToString = (val: string | undefined, addTimezone: boolean) => ({
    value: toHtmlDateTableFormat(val),
    setValueAs: (v: any) => (v ? v : null),
});
/**
 *  Set value to null if empty
 */
export const formHandleEmpty = {
    setValueAs: (v: any) => (v ? v : null),
};

/**
 *  Set value to null if empty
 */
export const formHandleEmptyNumbers = {
    setValueAs: (v: any) => (v || v === 0 ? v : null),
};

export const onChangeSSN = (key: string, handleChange: Function, newValue: String) => {
    console.log(newValue);
    console.log(key);
    console.log(newValue.length);
    if (newValue.length === 4 && newValue[3] !== "-") {
        handleChange(key, newValue.slice(0, 3) + "-" + newValue.slice(3, 4));
    } else if (newValue.length === 8 && newValue[7] !== "-") {
        handleChange(key, newValue.slice(0, 7) + "-" + newValue.slice(7, 8));
    } else if (newValue.length < 11) {
        handleChange(key, newValue);
    } else {
        handleChange(key, newValue.slice(0, 12));
    }
};
