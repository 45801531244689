import { AccountBalanceIcon } from "../../../icons";
import { Tooltip as ReactTooltip } from "react-tooltip";

export type ModuleProps = {
    name?: string;
    tooltip?: string;
};

export const Module = ({ name, tooltip }: ModuleProps) => {
    return (
        <div className="flex bg-reyna-grey-1 sm:rounded-[16px] sm:px-4 h-[30px] w-[30px] sm:w-min  rounded-full justify-center z-50 self-center">
            <div
                data-tip
                data-tooltip-id={tooltip ? name : ""}
                className="flex flex-row items-center gap-2 cursor-pointer"
            >
                <AccountBalanceIcon className={"w-[20px]"} />
                {name && (
                    <span className="text-sm font-inter whitespace-nowrap">
                        {name}
                    </span>
                )}
            </div>
            {tooltip ? (
                <ReactTooltip id={name}>
                    <span>{tooltip}</span>
                </ReactTooltip>
            ) : null}
        </div>
    );
};
