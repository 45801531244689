import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { API_BULK, API_BULK_BASEURL, buildUrl } from "../config";
import { fetcherPost, NOTIFY_CLIENT_SAVED } from "../utils";
import { getToken } from "../utils/auth";
import { processResponse } from "../utils/error";

export const useCreateReport = (
    succcessTrigger: Function
): [any, (val: {}) => void, boolean, () => void] => {
    const auth0 = useAuth0();

    const [isSaving, setSaving] = useState(false); // Used for loading indicator while saving
    const [data, setData] = useState({});

    const postUrl = buildUrl(API_BULK.POST_NEW_REPORT, {}, API_BULK_BASEURL);
    // build update url

    const saveFormData = () => {
        if (!isSaving) setSaving(true);
    };

    const handleReportData = (val: {}) => {
        setData({ ...data, ...val });
    };

    useEffect(() => {
        let mounted = true;
        const saveEdit = async () => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [result, error] = await fetcherPost(postUrl, await getToken(auth0), data);
            if (mounted) {
                processResponse(error, NOTIFY_CLIENT_SAVED);
                if (succcessTrigger) succcessTrigger();
                setSaving(false); // stop loading indicator for either case.
            }
        };

        if (isSaving) saveEdit();

        return () => {
            mounted = false;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSaving]);

    return [data, handleReportData, isSaving, saveFormData];
};
