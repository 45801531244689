/**
 * Libraries import
 */
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
/**
 * Build url configs & constants
 */
import { API_BASEURL, buildUrl } from "../config";
/**
 * Utils import
 */
import { fetcherPost } from "../utils";
import { getToken } from "../utils/auth";

/**
 * Hooks return type.
 */
type iReturn = [boolean, (data: any) => void];

/**
 * This hook is only able to handle PUT Request. It is used to update
 * data in the database. It is hardcoded to communicate with the API server.
 *
 * @param path A url path eg. /client/overall or /lead/overall
 * @param onSuccess A fuction called on success
 * @param onError A fucntion called on error
 * @returns
 */
export const useCreate = (path: string, onSuccess: Function, onError: Function): iReturn => {
    /**
     * Import auth0 to get token before making the post request
     */
    const auth0 = useAuth0();
    /**
     * Used to show a loading indicator while the saving in process
     */
    const [isSaving, setSaving] = useState(false);
    /**
     * Used to store formdata.
     */
    const [formdata, setFormdata] = useState<any>();
    /**
     * This function is called to save form data
     */
    const triggerSaveForm = (data: any) => {
        console.log(data);
        if (!isSaving) {
            setFormdata({ ...data });
            setSaving(true);
        }
    };

    useEffect(() => {
        /**
         * Used to prevent memory leaks.
         * Without this you will get a error msg react
         * cant perform a state update on an unmounted component
         *
         */
        let mounted = true;
        /**
         * Funciton to call on save
         */
        const saveFormData = async () => {
            /**
             * Build the url.
             */
            const postUrl = buildUrl(path, {}, API_BASEURL); // build update url
            /**
             * Make the put request to the database.
             */
            const [result, error] = await fetcherPost(postUrl, await getToken(auth0), formdata);
            /**
             * If the component is not longer mounted dont do anything, if not
             * show a notification on success or error & and call funciton in the component
             * to let it know how the process went.
             */
            if (mounted) {
                /**
                 * If there is an error, call the onError function else call the onSuccess function
                 * This two functions are passed as an argument when the hook is initialized.
                 */
                error ? onError(error) : onSuccess(result);
                /**
                 * Set the loading process to false after the put request has resolved.
                 */
                setSaving(false);
                setFormdata(null);
            }
        };
        /**
         * If saving has been set to true & formdata is
         * not empty call the saveFormData function.
         */
        if (isSaving && formdata) saveFormData();

        return () => {
            mounted = false;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSaving]);
    /**
     * Return the isSaving Boolean so it can be used as a loading indicator controller & a
     * function to to set the formdata and to trigger the saving process.
     *
     */
    return [isSaving, triggerSaveForm];
};
