import React from "react";
import Loader from "react-loader-spinner";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { API_BASEURL, buildUrl } from "../../config";
export type ButtonProps = {
    title: string;
    className?: string;
    Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    clickFunction?: (id?: string, key?: string) => void;
    iconClass?: string;
    url?: string;
    isLoading?: boolean;
    data?: { id?: string; key?: string };
    active?: boolean;
    disable?: boolean;
    type?: "button" | "submit" | "reset" | undefined;
    form?: string;
    titleClass?: string;
    role?: string;
};

function Button({
    title,
    className,
    Icon,
    clickFunction,
    iconClass = " w-[33px] h-[33px]",
    isLoading = false,
    data,
    active,
    url,
    disable = false,
    type = "button",
    form = "",
    role = "",
    titleClass,
}: ButtonProps) {
    const getIconClass = () => {
        if (iconClass.includes("text-")) return iconClass + " fill-current";
        return iconClass + " text-reyna-secondary fill-current";
    };
    return (
        <>
            <button
                data-tip
                data-tooltip-id={active === false ? title : ""}
                type={type}
                form={form}
                role={role}
                onClick={() => {
                    if (active !== false) {
                        (url &&
                            data?.key &&
                            window.open(
                                buildUrl(url || "", {}, API_BASEURL, {
                                    key: data?.key,
                                }),
                                "_blank"
                            )) ||
                            (clickFunction &&
                                clickFunction(data?.id && data?.id, data?.key && data?.key));
                    }
                }}
                disabled={disable}
                className={`button-crm flex justify-center items-center gap-2 fill-current flex-row ${className && className
                    }`}
            >
                {Icon && <Icon className={getIconClass()} />}
                <span className={titleClass}>{title}</span>
                {isLoading ? (
                    <Loader type="ThreeDots" color="#F9D949" height={20} width={20} />
                ) : (
                    <></>
                )}
            </button>
            {!active ? (
                <ReactTooltip id={title} place="bottom">
                    <span>Coming soon...</span>
                </ReactTooltip>
            ) : null}
        </>
    );
}

export default Button;
